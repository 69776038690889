.pte-section-1 {
  color: #fff;
  /* height: 500px; */
  background: url("../../assets/image/dummy/grebackgaround.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.pte-section-1 h1 {
  color: #fff;
  font-size: clamp(20px, 2vw, 40px);
  font-weight: bold;
}

.pte-section-1 .ielts-form .card-body {
  padding: 32px;
}

.pte-section-1 {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.pte-section-1 .submit-btn {
  border: none;
  padding: 10px 15px;
  background: dodgerblue;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  line-height: 23px;
}

.pte-section-5 h1 {
  text-align: center;
  font-size: clamp(25px, 2.5vw, 60px);
  font-weight: bold;
}

/* .pte-section-2 .ielts-cards {
  background: linear-gradient(180deg, #fff, #cee6ff);
  box-shadow: 0 0 20px lightgray;
  padding: 15px;
  text-align: center;
} */

/* .pte-section-2 .ielts-cards p {
  font-weight: 600;
} */

/* .pte-section-2 img {
  height: 100px;
} */

/* New CSS */

.pte-section-2 .container h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.pte-section-2 .points {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.pte-section-2 .point-card {
  /* height: 350px; */
  position: relative;
}

.pte-section-2 .point-card::before,
.pte-section-2 .point-card::after {
  width: 100%;
  height: 50%;
  position: absolute;
  content: "";
  background-color: #fff;
  z-index: -20;
  transition: all 0.4s;
  background-color: #fff;
}

.pte-section-2 .point-card::before {
  top: 0;
  right: 0;
}

.pte-section-2 .point-card::after {
  bottom: 0;
  left: 0;
}

.pte-section-2 .point-card .content {
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgba(24, 43, 60, 0.089);
  /* background-color: rgba(243,243,243 ,0.089); */
  backdrop-filter: blur(20px);
  border: 1px solid #fff;
  border-radius: 6px;
  /* box-shadow: 0 0 30px rgba(4, 19, 33, 0.31); */
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pte-section-2 .point-card .content img {
  width: 80%;
  height: 150px;
  margin-bottom: 1rem;
  object-fit: contain;
  /* border-radius: 50%; */
}

.pte-section-2 .point-card .content h3 {
  margin-bottom: 0.8em;
  font-size: 1.5em;
  text-align: center;
}

.pte-section-2 .point-card .content h3 small {
  display: block;
  font-size: 0.5em;
  font-weight: normal;
}

.pte-section-2 .point-card .content h5 {
  padding: 0.3em 1.5em;
  /* font-size: 0.8em; */
  text-align: center;
}

.pte-section-2 .point-card:hover::before {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: translate(20px, -20px);
  background-color: rgb(144, 144, 255);
}

.pte-section-2 .point-card:hover::after {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transform: translate(-30px, 30px);
  background-color: rgb(144, 144, 255);
}

.pte-section-2 .point-card:hover .content {
  color: #a6bfd3;
}


.pte-section-5 {
  background: rgb(206, 230, 255);
}

.pte-section-5 .ielts-faqs {
  margin-bottom: 10px;
}

.pte-section-5 .ielts-faqs .ielts-faqs-header {
  font-size: clamp(18px, 1.5vw, 25px);
  line-height: clamp(25px, 2.5vw, 50px);
  font-weight: 500;
  /* line-height: 45px; */
}

.pte-section-5 .ielts-faqs .ielts-faqs-content {
  font-size: clamp(15px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.PhoneInput.PhoneInput--focus input {
  outline: none !important;
}

.PhoneInput input {
  border: none !important;
}

.pte-section-3 h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 40px;
}

.pte-section-3 .feature-points {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  justify-items: center;
}

.pte-section-3 .feature-card {
  border-radius: 10px;

  background-color: #85CFF9;
  /* background-color: #ffffff; */
  overflow: hidden;
  padding: 20px;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  scale: 1;
  transition: all 0.3s ease, box-shadow 0.3s ease;
  outline: 1px solid rgb(52, 21, 66);
  outline-offset: -15px;
}

.pte-section-3 .feature-card:hover {
  scale: 1.01;
  outline-offset: 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.pte-section-3 .feature-icon {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.pte-section-3 .feature-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}



.pte-section-3 .feature-content h3 {
  text-align: center;
  font-size: 1.3rem;
  /* color: #34495e; */
  color: rgb(52, 21, 66);
  margin-bottom: 15px;
}

.pte-section-3 .feature-content p {
  font-size: 1rem;
  text-align: center;
  /* color: #7f8c8d; */
  color: #341542;
}


.pte-section-6 h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 40px;
}

.pte-section-6 .plan-points {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.pte-section-6 .plan-card {
  height: 400px;
  width: 400px;
  border-radius: 50%;
  background-color: #ffffff;
  overflow: hidden;
  padding: 20px;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  transition: transform 0.5s ease;
  /* outline: 1px solid rgb(52, 21, 66); */
  /* border: 2px solid skyblue; */
  outline-offset: 2px;
  justify-content: center;
  align-items: center;
  justify-self: center;

}

.pte-section-6 .plan-card:hover {
  transform: scale(1.1);
  /* box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15); */
}

.pte-section-6 .plan-icon {
  width: 80%;
  text-align: center;
  border-radius: 10px;
  position: relative;
}

.pte-section-6 .plan-image {
  width: 30%;
  object-fit: contain;
  transition: transform 0.3s ease;
}



.pte-section-6 .plan-content h3 {
  text-align: center;
  font-size: 1.3rem;
  color: #34495e;

  margin-bottom: 15px;
}

.pte-section-6 .plan-content p {
  font-size: 1rem;
  text-align: center;
  color: #7f8c8d;
}

.pte-section-4 {
  background: #fff;
}

.pte-section-4 .testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

/* Testimonial Card */
.testimonial-card {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.avatar {
  width: 70px;
  border-radius: 50%;
  border: 3px solid #ff6347;
}

.testimonial-name {
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
}

.testimonial-role {
  font-size: 0.9rem;
  color: #666;
}

.testimonial-text {
  font-size: 1.1rem;
  color: #555;
  font-style: italic;
  margin: 0 10px;
  padding-top: 10px;
  position: relative;
}

.testimonial-text::before {
  content: "“";
  font-size: 3rem;
  color: #ff6347;
  position: absolute;
  top: -20px;
  left: 10px;
}

.testimonial-text::after {
  content: "”";
  font-size: 3rem;
  color: #ff6347;
  position: absolute;
  bottom: -10px;
  right: 10px;
}

/* Call to Action Section */
.cta-section {
  background-color: cornflowerblue;
  color: white;
  padding: 80px 20px;
  text-align: center;
  margin-top: 50px;
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
}

.cta-title {
  color: #fff;
  font-size: clamp(25px, 2.5vw, 60px);
  font-weight: 700;
  margin-bottom: 20px;
}

/* .cta-description {
    font-size: 1.2rem;
    margin-bottom: 40px;
} */

.cta-button {
  cursor: pointer;
  display: inline-block;
  padding: 15px 40px;
  background-color: white;
  color: cornflowerblue;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cta-button:hover {
  color: cornflowerblue;
  box-shadow: 0 0 0 5px #ffffff57, 0 0 0 10px #ffffff3b, 0 0 0 15px #ffffff17;
  transition: 0.2s linear;
}

.play-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 50px;
  background-color: transparent;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  color: #fff;
}

.play-button .play-icon {
  margin-right: 10px;
  font-size: 20px;
  transition: all 0.3s ease;
  color: #fff;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.line-height-3rem {
  line-height: 3rem;
}

/* Responsive Design */
@media (min-width: 768px) {
  .testimonial-grid {
    grid-template-columns: 1fr;
  }

  .cta-title {
    font-size: 2rem;
  }
}

@media (max-width: 1140px) {
  .pte-section-2 .point-card {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .pte-section-3 .feature-card {
    align-items: flex-start;
  }

  .pte-section-2 .point-card {
    flex: 1 1 100%;
  }

  .pte-section-3 .feature-points {
    grid-template-columns: 1fr;
  }

  .pte-section-6 .plan-points {
    grid-template-columns: 1fr;
  }

  .pte-section-2 .points {
    grid-template-columns: 1fr;
  }

}


@media (min-width: 768px) and (max-width: 1024px) {
  .pte-section-3 .feature-points {
    grid-template-columns: repeat(2, 1fr);
  }
  .pte-section-6 .plan-points {
    grid-template-columns: 1fr;
  }


  /* .pte-section-6 .plan-points {
    grid-template-columns: repeat(2, 1fr);
    grid-row: 2 / 3;
  } */
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .pte-section-6 .plan-points {
    grid-template-columns: repeat(2, 1fr);
    grid-row: 2 / 3;
  }
}