.megaseminar-banner-section-1 {
    height: 600px;
    width: 100%;
    background-image: url("../../../assets/image/megaseminar/megaseminarwebbanner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0px -10px 31px #00000059;
}

.megaseminar-section-2 {
    text-align: center;
    font-family: "Poppins", sans-serif;
    overflow: hidden;
    background: #D9E8FF;
}

.megaseminar-section-2 .header h1 {
    color: #275AA6;
    margin-bottom: 15px;
    font-weight: 700;
    animation: fadeInDown 1s ease-in-out;
    font-size: clamp(25px, 2.5vw, 50px);
}

.megaseminar-section-2 .header p {
    color: #555;
    animation: fadeInUp 1s ease-in-out;
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
}

.megaseminar-section-2 .highlights-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.megaseminar-section-2 .highlight-card {
    position: relative;
    background: #ffffff;
    border: 2px solid #1e88e5;
    border-radius: 12px;
    padding: 20px;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    overflow: hidden;
    animation: zoomIn 0.5s ease forwards;
    cursor: pointer;
}

.megaseminar-section-2 .highlight-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    background: linear-gradient(45deg, skyblue, #275AA6);
}

.megaseminar-section-2 .icon-wrapper {
    width: 80px;
    height: 80px;
    background: linear-gradient(135deg, #E65371, #ffbbc9);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 15px;
    animation: bounce 2s infinite;
}

.megaseminar-section-2 .icon {
    font-size: 2.5rem;
    color: #fff;
}

.megaseminar-section-2 .text-wrapper p {
    font-size: 1rem;
    color: #333;
    font-weight: 500;
    line-height: 1.6;
    animation: fadeIn 0.8s ease;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes jump {

    0%,
    100% {
        transform: translateY(0) rotate(270deg);
    }

    50% {
        transform: translateY(-10px) rotate(270deg);
    }
}

.megaseminar-section-11,
.megaseminar-section-6,
.megaseminar-section-4 {
    background: #D9E8FF;
    position: relative;
    overflow: hidden;
}

.megaseminar-section-11 h1,
.megaseminar-section-6 h1,
.megaseminar-section-4 h1 {
    font-size: clamp(25px, 2.5vw, 50px);
    color: #275AA6;
}

.megaseminar-section-11 p,
.megaseminar-section-6 p,
.megaseminar-section-4 p {
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
    color: #275AA6;
}

.megaseminar-section-11 p {
    text-align: justify;
}

.megaseminar-section-11 p.university-dean {
    font-size: clamp(11px, 1vw, 15px);
    line-height: clamp(15px, 2.5vw, 20px);
}

.megaseminar-section-4 h3 {
    font-size: clamp(20px, 2vw, 40px);
    color: #275AA6;
}

.megaseminar-section-4 table tr,
.megaseminar-section-4 table td {
    border: none;
}

.megaseminar-section-4 .key-icon {
    animation: jump 1s infinite;
}

.megaseminar-section-4 .bubble {
    height: 300px;
    width: 300px;
    background: #d9e8ff63;
    position: absolute;
    bottom: -50px;
    right: -50px;
    border-radius: 50%;
    box-shadow: 10px 10px 10px #67676712, inset 10px 10px 25px #ffffff69;
    animation: fadeIn 1s ease-in;
    z-index: 1;
}

.megaseminar-section-13,
.megaseminar-section-12,
.megaseminar-section-7,
.megaseminar-section-5 {
    position: relative;
    overflow: hidden;
    background: #275AA6;
}

.megaseminar-section-13 h1,
.megaseminar-section-12 h1,
.megaseminar-section-7 h1,
.megaseminar-section-5 h1 {
    font-size: clamp(25px, 2.5vw, 50px);
    color: #fff;
}

.megaseminar-section-12 p,
.megaseminar-section-7 p,
.megaseminar-section-5 p {
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
    color: #fff;
}

.megaseminar-section-5 h3 {
    font-size: clamp(20px, 1.5vw, 40px);
    color: #fff;
}

.megaseminar-section-5 table {
    color: #000;
    background: #fff;
    font-size: clamp(11px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
}

.megaseminar-section-5 table th {
    color: #000;
    background: #c4e0ff;
}

.megaseminar-section-5 table tr:hover {
    color: #000;
    background: #d5e9ff;
}

/* .megaseminar-section-5 table tr,
.megaseminar-section-5 table td {
    border: none;
} */

.megaseminar-section-6 ol {
    font-family: system-ui, sans-serif;
    color: hsl(0 0% 95%);

    width: fit-content;
    /* max-width: 30rem; */
    margin-block: 0;
    padding-block: 2rem;
    padding-inline: 0;

    display: grid;
    gap: 3rem;

    list-style: none;
    counter-reset: liCount;
    width: 100%;
}

.megaseminar-section-6 ol>li {
    --border-radius: 1rem;
    --padding: 0.5rem;
    --number-size: 3rem;
    --indent: 3.5rem;

    display: grid;
    --text-column: minmax(calc(var(--number-size) * 2), 1fr);
    grid-template-columns: var(--number-size) var(--text-column);
    gap: 0.5rem;
    grid-auto-flow: dense;
    align-items: center;
    box-shadow: 2px 2px 5px hsl(0 0% 0% / .5);

    margin-inline: var(--indent) 0rem;
    padding-block: var(--padding);
    padding-inline: var(--padding) calc(var(--padding) * 2);
    border-radius: var(--border-radius);
    background-color: var(--accent-color);
    counter-increment: liCount;
    cursor: pointer;
}

.megaseminar-section-6 ol>li:hover {
    transition: all 0.2s ease;
    background: #275AA6;
}

.megaseminar-section-6 ol>li::before,
.megaseminar-section-6 ol>li::after {
    content: "";
    grid-area: 1/1;
    background-color: var(--accent-color);
    background-image: linear-gradient(hsl(0 0% 100%) 0 0);
}

.megaseminar-section-6 ol>li::before {
    content: counter(liCount, decimal-leading-zero);
    font-size: 1.5rem;
    color: var(--accent-color);
    aspect-ratio: 1;
    border-radius: calc(var(--border-radius) - var(--padding));
    display: grid;
    place-items: center;
    box-shadow: inset 0 0 2px hsl(0 0% 0% / .75);
}

.megaseminar-section-6 ol>li::after {
    --scale: 2;
    aspect-ratio: 1/1;
    border-radius: calc(var(--border-radius) / var(--scale));
    scale: var(--scale);
    rotate: 45deg;
    z-index: -1;
    box-shadow:
        calc(var(--multi, 1) * -1px) calc(var(--multi, 1) * 1px) 5px hsl(0 0% 0% / .5),
        calc(var(--multi, 1) * -.5rem) calc(var(--multi, 1) * .5rem) 0 0 var(--accent-color);
}

.megaseminar-section-6 ol>li:nth-child(even) {
    --multi: -1;
    margin-inline: 0rem var(--indent);
    padding-inline: calc(var(--padding) * 2) var(--padding);
    grid-template-columns: var(--text-column) var(--number-size);
}

.megaseminar-section-6 ol>li:nth-child(even)::before,
.megaseminar-section-6 ol>li:nth-child(even)::after {
    grid-area: 1/2;
}


.megaseminar-section-7 ul.list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.megaseminar-section-7 ul.list li {
    display: flex;
    padding: 15px;
    flex-direction: column;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    background-color: #fff;
    border-right: 4px solid #fff;
    transition: all 0.3s ease;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.megaseminar-section-7 ul.list li .title {
    font-weight: 600;
    /* font-size: 18px; */
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
    position: relative;
    z-index: 1;
    /* line-height: 24px; */
}


.megaseminar-section-7 ul.list li:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    transition: width 0.3s ease;
}

.megaseminar-section-7 ul.list li:hover {
    color: #fff;
}

.megaseminar-section-7 ul.list li:hover:after {
    width: 100%;
    transition: width 0.3s ease;
}

/* Shopping */
.megaseminar-section-7 ul.list li.shopping:after {
    background: linear-gradient(102deg, #1061b7 0%, #cc00c5 100%);
}

.megaseminar-section-7 ul.list li.shopping {
    border-right-color: #cc00c5;
}

/* Personal */
.megaseminar-section-7 ul.list li.personal:after {
    background: linear-gradient(102deg, #00cc88 0%, #1096b7 100%);
}

.megaseminar-section-7 ul.list li.personal {
    border-right-color: #1096b7;
}

/* Work */
.megaseminar-section-7 ul.list li.work:after {
    background: linear-gradient(102deg, #fa5437 0%, #ed4618 100%);
}

.megaseminar-section-7 ul.list li.work {
    border-right-color: #ed4618;
}

.megaseminar-section-8 {
    background: #DFE6F9;
}

.megaseminar-section-8 h1 {
    font-size: clamp(25px, 2.5vw, 50px);
    color: #275AA6;
}

.megaseminar-section-8 p {
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
    color: #275AA6;
}

.megaseminar-section-8 .interview-image {
    float: inline-end;
}

.megaseminar-section-3 h2,
.megaseminar-section-8 h2 {
    font-size: clamp(25px, 3vw, 70px);
    line-height: clamp(25px, 4vw, 70px);
    color: #fff;
    text-shadow: 0 0 10px #00000087;
    position: relative;
    z-index: 1;
}

.megaseminar-section-8 .man {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
}

.megaseminar-section-8 .event-banner-btn {
    background: #E65272;
    color: white;
    text-decoration: none;
    padding: 15px 50px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.megaseminar-section-8 .event-banner-btn:hover {
    background: #db254d;
    color: white;
}

.megaseminar-section-3 .man {
    clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
}

.megaseminar-section-9 {
    background: #275AA6;
    color: #fff;
}

.megaseminar-section-9 h1 {
    color: #fff;
    font-size: clamp(25px, 2.5vw, 50px);
}

.megaseminar-section-9 p {
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
}

.megaseminar-section-9 .swiper-slide {
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 8px;
    height: auto;
}

.megaseminar-section-9 .swiper-pagination-bullet {
    background: #fff;
}

.megaseminar-section-9 .testimonial-para {
    font-style: italic;
    font-size: clamp(13px, 1.5vw, 15px);
    line-height: clamp(20px, 2.5vw, 20px);
    color: #353535;
}

.megaseminar-section-9 .achivement {
    font-size: 15px;
    line-height: clamp(20px, 2.5vw, 20px);
}

.megaseminar-section-9 .testimonial-avatar {
    width: 160px;
    height: 200px;
    object-fit: cover;
    float: inline-start;
    border-radius: 5px;
    margin: 0 10px 10px 0;
}

.megaseminar-section-10 {
    background: #DFE6F9;
}

.megaseminar-form {
    max-width: 500px;
}

.megaseminar-form .submit-btn {
    background: #E65272;
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.megaseminar-form .submit-btn:hover {
    background: #e23e62;
    color: #fff;
    transition: all 0.2s ease;
}

.megaseminar-section-12 .man-avatar-wrapper {
    display: flex;
    justify-content: end;
    align-items: end;
}

.megaseminar-section-13 .alokbansal {
    border-radius: 4% 25% 4% 25% / 4% 25% 5% 25%;
    box-shadow: 0 0 10px #00000080;
    outline: 1px solid black;
    outline-offset: 3px;
    border: 3px solid black;
    float: inline-start;
    margin: 0 15px 15px 0;
}

.megaseminar-section-13 p {
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
    color: #fff;
    text-align: justify;
}

@media (max-width: 1600px) {
    .megaseminar-banner-section-1 {
        height: 500px;
    }
}

@media (max-width: 1300px) {
    .megaseminar-banner-section-1 {
        height: 400px;
    }
}

@media (max-width: 1050px) {
    .megaseminar-banner-section-1 {
        height: 300px;
    }
}

@media (max-width: 991px) {
    .megaseminar-section-12 .man-avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: end;
    }
}

@media (max-width: 800px) {
    .megaseminar-banner-section-1 {
        height: 230px;
    }
}

@media (max-width: 768px) {
    .megaseminar-section-8 .interview-image {
        float: none;
    }

}

@media (max-width: 610px) {
    .megaseminar-banner-section-1 {
        height: 180px;
    }
}

@media (max-width: 450px) {
    .megaseminar-banner-section-1 {
        background-image: url("../../../assets/image/megaseminar/megaseminarwebbannermobile.jpg");
        height: 600px;
    }

    .megaseminar-section-13 .alokbansal {
        float: none;
        text-align: center;
        display: block;
        margin: 20px auto;
    }
}

@media (max-width: 385px) {
    .megaseminar-banner-section-1 {
        background-image: url("../../../assets/image/megaseminar/megaseminarwebbannermobile.jpg");
        height: 493px;
    }

    .megaseminar-section-13 .alokbansal {
        float: none;
        text-align: center;
        display: block;
        margin: 20px auto;
    }
}