.ielts2-section-1 {
    background-color: #000B20;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.9;
    z-index: 2;
}

.ielts2-section-1 .logo {
    width: 150px
}

.ielts2-section-1 .callus-btn {
    text-decoration: none;
    padding: 6px 25px;
    color: rgb(255, 207, 0);
    border: 1px solid rgb(255, 207, 1);
    box-shadow: rgb(255, 207, 0) 0px 0px 5px, rgb(255, 207, 0) 0px 0px 5px inset;
    text-shadow: rgb(255, 207, 0) 0px 0px 5px;
    border-radius: 5px;
}



.ielts2-section-2 {
    margin-top: 69px !important;
    background-image: linear-gradient(140deg, #000000 32%, #224B9A 72%);
    color: white;
}

.ielts2-section-2 h1 {
    color: #fff;
    font-size: clamp(20px, 2.5vw, 50px);
}

/* .ielts2-section-4 p, */
.ielts2-section-2 p {
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
}

.ielts2-section-2 .mocktest {
    width: 80%;
    border-radius: 10px;
}

.ielts2-form .submit-btn {
    text-decoration: none;
    padding: 6px 25px;
    color: rgb(255, 207, 0);
    border: 2px solid rgb(255, 207, 1);
    border-radius: 5px;
    background: transparent;
    width: 100%;
    transition: all 0.3s ease;
}

.ielts2-section-1 .callus-btn:focus,
.ielts2-section-1 .callus-btn:hover,
.ielts2-form .submit-btn:focus,
.ielts2-form .submit-btn:hover {
    background: rgb(255, 207, 0);
    color: #000;
    box-shadow: 0 0 10px #000, 0 0 20px rgb(255, 207, 0);
    outline: none;
}

.ielts2-form select,
.ielts2-form input {
    background: transparent;
    color: #fff;
}

.ielts2-form .col-form-label {
    color: #fff;
}

.ielts2-form input::placeholder {
    color: #fff;
}


.ielts2-section-3 {
    background: #000;
    color: #fff;
}

.ielts2-section-4 h1 {
    color: black;
    font-weight: 300;
    font-size: clamp(30px, 3vw, 50px);
    /* font-size: 50px; */
}

.ielts2-section-4 p {
    margin: 5px 0 10px;
    font-size: clamp(18px, 3vw, 20px);
    /* font-size: 20px; */
}

.ielts2-section-4 .check-icon {
    color: #5AFA3B;
    font-size: clamp(13px, 2.5vw, 30px);
    vertical-align: baseline;
}

.ielts2-section-4 table td {
    border: none;
}

.ielts2-section-4 .mocktest {
    width: 80%;
}

.ielts2-section-5 {
    background: #FFFFFF;
}

.ielts2-section-5 .coursecompare {
    width: 80%;
}

.ielts2-section-6 {
    background: #054199;
    color: #fff;
}

.ielts2-section-6 h1 {
    color: #fff;
    font-size: clamp(20px, 2.5vw, 50px);
    font-weight: 400;
}

.ielts2-section-6 p {
    font-size: clamp(13px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
}

.ielts2-section-6 .swiper-slide {
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 8px;
    height: auto;
}

.ielts2-section-6 .swiper-pagination-bullet {
    background: #fff;
}

.ielts2-section-6 .testimonial-para {
    font-size: 15px;
    font-style: italic;
    line-height: clamp(20px, 2.5vw, 25px);
    text-align: center;
    color: #353535;
}

.ielts2-section-6 .achivement {
    font-size: 15px;
    line-height: clamp(20px, 2.5vw, 20px);
    text-align: center;
}

.ielts2-section-6 .testimonial-avatar {
    width: 75px;
    margin: auto;
    border-radius: 50%;
    outline: 2px solid black;
    outline-offset: 1px;
}



.ielts2-section-7 img {
    width: 35%;
    border-radius: 10px;
}

.ielts2-section-7 .faq-card {
    display: flex;
    align-items: flex-start;
}

.ielts2-section-7 h4 {
    font-size: clamp(15px, 1.5vw, 25px);
    line-height: clamp(15px, 1.5vw, 25px);
    margin-bottom: 10px;
}

.ielts2-section-7 p {
    font-size: clamp(12px, 1.5vw, 20px);
    line-height: clamp(13px, 1.5vw, 25px);
}

.ielts2-section-7 .callus-btn {
    color: #054199;
    text-decoration: none;
    border: 1px solid #054199;
    padding: 3px 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.ielts2-section-7 .callus-btn:focus,
.ielts2-section-7 .callus-btn:hover {
    background: #054199;
    color: #fff;
    outline: none;
}

.ielts2-section-8 img {
    width: 100% !important;
}

.ielts2-section-8 .accordion-button {
    line-height: 25px;
    background: #444444 !important;
    color: #fff !important;
    margin: 0;
}

.ielts2-section-8 .accordion-button.collapsed {
    color: black !important;
}

.ielts2-section-8 .accordion-item {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
}

.ielts2-section-9 {
    background: #054199;
    color: #fff;
}

.ielts2-section-9 .logo {
    width: 150px;
}

.ielts2-section-9 a {
    color: #fff !important;
    text-decoration: none !important;
    position: relative;
}

.ielts2-section-9 a i {
    position: absolute;
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ielts2-section-9 .footer-socialmedia :hover {
    background-color: transparent;
    color: #fff;
}


/* .ielts2-section-8 .accordion-body{
    background: #444444 !important;
    color: #fff !important;
    line-height: 25px;
} */

.footer-branch-sec {
    padding-top: 10px;
}

.footer-branch-sec ul {
    list-style: none;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

/* .footer-branch-sec ul li {
    background: aliceblue;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
} */

.footer-branch-sec ul li a {
    color: black !important;
    background: aliceblue;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
}

.footer-branch-sec ul li a:hover,
.footer-branch-sec ul li a:focus {
    color: #fff !important;
    background: #E63439;
    transition: all 0.2s linear;
}

@media (max-width: 767px) {
    .ielts2-section-7 img {
        width: 100%;
    }

    .ielts2-section-7 .faq-card {
        display: block;
    }

    .ielts2-section-7 h4 {
        font-size: clamp(20px, 1.5vw, 30px);
        line-height: clamp(20px, 1.5vw, 30px);
        margin-bottom: 10px;
    }

    .ielts2-section-7 p {
        font-size: clamp(15px, 1.5vw, 25px);
        line-height: clamp(20px, 1.5vw, 30px);
    }

    .ielts2-section-8 img {
        width: 30% !important;
    }


}

@media (max-width: 991px) {
    .ielts2-section-2 .mocktest {
        width: 100%;
    }

}