.sat-section-1 {
  color: #fff;
  /* height: 500px; */
  background: url("../../assets/image/dummy/grebackgaround.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.sat-section-1 h1 {
  color: #fff;
  font-size: clamp(20px, 2vw, 40px);
  font-weight: bold;
}

.sat-section-1 .ielts-form .card-body {
  padding: 32px;
}

.sat-section-1 {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.sat-section-1 .submit-btn {
  border: none;
  padding: 10px 15px;
  background: dodgerblue;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  line-height: 23px;
}

.sat-section-5 h1 {
  text-align: center;
  font-size: clamp(25px, 2.5vw, 60px);
  font-weight: bold;
}

/* .sat-section-2 .ielts-cards {
    background: linear-gradient(180deg, #fff, #cee6ff);
    box-shadow: 0 0 20px lightgray;
    padding: 15px;
    text-align: center;
  } */

/* .sat-section-2 .ielts-cards p {
    font-weight: 600;
  } */

/* .sat-section-2  {
    background-color: cornflowerblue;
  } */

/* New CSS */
.sat-section-new {
  background-color: #f1f1f1;
}

.sat-section-2 .container h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.sat-section-2 .points {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.sat-section-2 .point-card {
  flex: 1 1 calc(33.333% - 40px);
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.sat-section-2 .point-card:hover {
  transform: translateY(-10px);
}

.sat-section-2 .point-card h3 {
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 15px;
}

.sat-section-2 .point-card p {
  font-size: 1rem;
  color: #7f8c8d;
  margin: 0;
}

.sat-section-2 .icon {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.sat-section-2 .icon img {
  height: 100%;
  mix-blend-mode: darken;
  transition: transform 0.3s ease;
}

.sat-section-2 .point-card:hover img {
  transform: scale(1.1);
}

.sat-section-5 {
  background: rgb(206, 230, 255);
}

.sat-section-5 .ielts-faqs {
  margin-bottom: 10px;
}

.sat-section-5 .ielts-faqs .ielts-faqs-header {
  font-size: clamp(18px, 1.5vw, 25px);
  line-height: clamp(25px, 2.5vw, 50px);
  font-weight: 500;
  /* line-height: 45px; */
}

.sat-section-5 .ielts-faqs .ielts-faqs-content {
  font-size: clamp(15px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.PhoneInput.PhoneInput--focus input {
  outline: none !important;
}

.PhoneInput input {
  border: none !important;
}

.sat-section-4 {
  background: #fff;
}

.sat-section-4 .testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

/* Testimonial Card */
.testimonial-card {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.avatar {
  width: 70px;
  border-radius: 50%;
  border: 3px solid #ff6347;
}

.testimonial-name {
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
}

.testimonial-role {
  font-size: 0.9rem;
  color: #666;
}

.testimonial-text {
  font-size: 1.1rem;
  color: #555;
  font-style: italic;
  margin: 0 10px;
  padding-top: 10px;
  position: relative;
}

.testimonial-text::before {
  content: "“";
  font-size: 3rem;
  color: #ff6347;
  position: absolute;
  top: -20px;
  left: 10px;
}

.testimonial-text::after {
  content: "”";
  font-size: 3rem;
  color: #ff6347;
  position: absolute;
  bottom: -10px;
  right: 10px;
}

/* Call to Action Section */
.cta-section {
  background-color: cornflowerblue;
  color: white;
  padding: 80px 20px;
  text-align: center;
  margin-top: 50px;
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
}

.cta-title {
  color: #fff;
  font-size: clamp(25px, 2.5vw, 60px);
  font-weight: 700;
  margin-bottom: 20px;
}

/* .cta-description {
      font-size: 1.2rem;
      margin-bottom: 40px;
  } */

.cta-button {
  cursor: pointer;
  display: inline-block;
  padding: 15px 40px;
  background-color: white;
  color: cornflowerblue;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cta-button:hover {
  color: cornflowerblue;
  box-shadow: 0 0 0 5px #ffffff57, 0 0 0 10px #ffffff3b, 0 0 0 15px #ffffff17;
  transition: 0.2s linear;
}

.play-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 50px;
  background-color: transparent;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  color: #fff;
}

.play-button .play-icon {
  margin-right: 10px;
  font-size: 20px;
  transition: all 0.3s ease;
  color: #fff;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.line-height-3rem {
  line-height: 3rem;
}

.sat-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  justify-items: center;
}

.satcontent h3 {
  font-size: 20px;
  /* color: rgb(52, 21, 66); */
}

.sat-container .satcard {
  border: 5px solid #f5f7ff;
  margin: 10px 0;
  position: relative;
  background: white;
  padding: 100px 40px 60px;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.sat-container .satcard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: skyblue;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s;
}

.sat-container .satcard:hover .satimage {
  opacity: 1;
  transform: translateY(-40px);
}

.sat-container .satcard:hover::before {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.sat-container .satcard .satimage {
  position: absolute;
  color: darkgray;
  left: 40px;
  top: 45px;
  width: 100px;
  font-weight: 800;
  z-index: 1;
  opacity: 0.5;
  transition: 0.5s;
}

.sat-container .satcard .satcontent {
  position: relative;
  font-size: 1.4rem;
  line-height: 2rem;
  z-index: 2;
  color: #333;
  transition: 0.5s;
  padding-top: 50px;
}

.sat-features {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  text-align: start;
}

.img-contain {
  display: flex;
  justify-content: center;
  align-items: center;

  /* flex-direction: column; */

  gap: 20px;
  /* max-width: 00px; */
}

.img-contain h3 {
  font-size: 18px;
  line-height: 40px;
}

.img-contain img {
  height: 70px;
  width: 70px;
}

.blog_post {
  background: #fff;
  /* max-width: 500px; */
  max-width: 550px;
  max-height: 350px;
  border-radius: 10px;
  box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
  position: relative;
}

.container_copy {
  padding: 6rem 4rem 5rem 4rem;
}

.img_pod img {
  height: 180px;
  box-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.container_copy h3 {
  margin: 0 0 0.5rem 0;
  color: #999;
  font-size: 1.25rem;
}

.container_copy h1 {
  margin: 0 0 1rem 0;
  font-size: 2.5rem;
  letter-spacing: 0.5px;
  color: #333;
}

.container_copy p {
  margin: 0 0 4.5rem 0;
  font-size: 1.5rem;
  line-height: 1.45;
  color: #333;
}

.preparation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;

  width: 100%;
}

.sat-abroad {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap-reverse;
}

/* ADD BY JYOTINDRA */
.sat-section-3 h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 40px;
}

.sat-section-3 .feature-points {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  justify-items: center;
}

.sat-section-3 .feature-card {
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 20px;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  scale: 1;
  transition: all 0.3s ease, box-shadow 0.3s ease;
}

.sat-section-3 .feature-card:hover {
  background-color: #85cff9;
}

.sat-section-3 .feature-icon {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.sat-section-3 .feature-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.sat-section-3 .feature-content h3 {
  text-align: center;
  font-size: 1.3rem;
  /* color: #34495e; */
  color: rgb(52, 21, 66);
  margin-bottom: 15px;
}

.sat-section-3 .feature-content p {
  font-size: 1rem;
  text-align: center;
  /* color: #7f8c8d; */
  color: #341542;
}

/* END HERE */

/* Responsive Design */
@media (min-width: 768px) {
  .testimonial-grid {
    grid-template-columns: 1fr;
  }

  .cta-title {
    font-size: 2rem;
  }
}

@media (max-width: 1140px) {
  .sat-section-2 .point-card {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .sat-section-3 .feature-points {
    grid-template-columns: 1fr;
  }

  .sat-section-2 .point-card {
    flex: 1 1 100%;
  }

  .sat-section-3 .help-points {
    grid-template-columns: 1fr;
  }

  .sat-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sat-section-3 .feature-points {
    grid-template-columns: repeat(2, 1fr);
  }

  .sat-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
