.gre-section-1 {
  color: #fff;
  /* height: 500px; */
  background: url("../../assets/image/dummy/grebackgaround.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gre-section-1 h1 {
  color: #fff;
  font-size: clamp(20px, 2vw, 40px);
  font-weight: bold;
}

.gre-section-1 .ielts-form .card-body {
  padding: 32px;
}

.gre-section-1 {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.gre-section-1 .submit-btn {
  border: none;
  padding: 10px 15px;
  background: dodgerblue;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  line-height: 23px;
}

.gre-section-5 h1 {
  text-align: center;
  font-size: clamp(25px, 2.5vw, 60px);
  font-weight: bold;
}

/* .gre-section-2 .ielts-cards {
  background: linear-gradient(180deg, #fff, #cee6ff);
  box-shadow: 0 0 20px lightgray;
  padding: 15px;
  text-align: center;
} */

/* .gre-section-2 .ielts-cards p {
  font-weight: 600;
} */

/* .gre-section-2 img {
  height: 100px;
} */

/* New CSS */

.gre-section-2 .container h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.gre-section-2 .points {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.gre-section-2 .point-card {
  flex: 1 1 calc(33.333% - 40px);
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.gre-section-2 .point-card:hover {
  transform: translateY(-10px);
}

.gre-section-2 .point-card h3 {
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 15px;
}

.gre-section-2 .point-card p {
  font-size: 1rem;
  color: #7f8c8d;
  margin: 0;
}

.gre-section-2 .icon {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.gre-section-2 .icon img {
  height: 100%;
  mix-blend-mode: darken;
  transition: transform 0.3s ease;
}

.gre-section-2 .point-card:hover img {
  transform: scale(1.1);
}

.gre-section-5 {
  background: rgb(206, 230, 255);
}

.gre-section-5 .ielts-faqs {
  margin-bottom: 10px;
}

.gre-section-5 .ielts-faqs .ielts-faqs-header {
  font-size: clamp(18px, 1.5vw, 25px);
  line-height: clamp(25px, 2.5vw, 50px);
  font-weight: 500;
  /* line-height: 45px; */
}

.gre-section-5 .ielts-faqs .ielts-faqs-content {
  font-size: clamp(15px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.PhoneInput.PhoneInput--focus input {
  outline: none !important;
}

.PhoneInput input {
  border: none !important;
}

.gre-section-3 h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 40px;
}

.gre-section-3 .help-points {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 30px;
}

.gre-section-3 .help-card {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  text-align: left;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gre-section-3 .help-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}

.gre-section-3 .help-icon {
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}

.gre-section-3 .help-image {
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gre-section-3 .help-card:hover .help-image {
  transform: scale(1.05);
}

.gre-section-3 .help-content h3 {
  font-size: 1.3rem;
  color: #34495e;
  margin-bottom: 15px;
}

.gre-section-3 .help-content p {
  font-size: 1rem;
  color: #7f8c8d;
}

.gre-section-4 {
  background: #fff;
}

.gre-section-4 .testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

/* Testimonial Card */
.testimonial-card {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.avatar {
  width: 70px;
  border-radius: 50%;
  border: 3px solid #ff6347;
}

.testimonial-name {
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
}

.testimonial-role {
  font-size: 0.9rem;
  color: #666;
}

.testimonial-text {
  font-size: 1.1rem;
  color: #555;
  font-style: italic;
  margin: 0 10px;
  padding-top: 10px;
  position: relative;
}

.testimonial-text::before {
  content: "“";
  font-size: 3rem;
  color: #ff6347;
  position: absolute;
  top: -20px;
  left: 10px;
}

.testimonial-text::after {
  content: "”";
  font-size: 3rem;
  color: #ff6347;
  position: absolute;
  bottom: -10px;
  right: 10px;
}

/* Call to Action Section */
.cta-section {
  background-color: cornflowerblue;
  color: white;
  padding: 80px 20px;
  text-align: center;
  margin-top: 50px;
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
}

.cta-title {
  color: #fff;
  font-size: clamp(25px, 2.5vw, 60px);
  font-weight: 700;
  margin-bottom: 20px;
}

/* .cta-description {
    font-size: 1.2rem;
    margin-bottom: 40px;
} */

.cta-button {
  cursor: pointer;
  display: inline-block;
  padding: 15px 40px;
  background-color: white;
  color: cornflowerblue;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cta-button:hover {
  color: cornflowerblue;
  box-shadow: 0 0 0 5px #ffffff57, 0 0 0 10px #ffffff3b, 0 0 0 15px #ffffff17;
  transition: 0.2s linear;
}

.play-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 50px;
  background-color: transparent;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  color: #fff;
}

.play-button .play-icon {
  margin-right: 10px;
  font-size: 20px;
  transition: all 0.3s ease;
  color: #fff;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.line-height-3rem {
  line-height: 3rem;
}

/* Responsive Design */
@media (min-width: 768px) {
  .testimonial-grid {
    grid-template-columns: 1fr;
  }

  .cta-title {
    font-size: 2rem;
  }
}

@media (max-width: 1140px) {
  .gre-section-2 .point-card {
    flex: 1 1 calc(50% - 40px);
  }
}

@media (max-width: 768px) {
  .gre-section-3 .help-card {
    align-items: flex-start;
  }
  .gre-section-2 .point-card {
    flex: 1 1 100%;
  }
  .gre-section-3 .help-points {
    grid-template-columns: 1fr;
  }
}
