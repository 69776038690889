@import url(https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url("https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap");

.css-dot::after,
.css-dot::before,
.popular button.owl-next::after,
.popular-para li,
.studyplan-section,
select {
  background-repeat: no-repeat;
}

.owl-theme .owl-nav,
h1,
h2,
h3,
h5 {
  margin-top: 0;
}

.dropdown-header,
h1,
h2,
h3,
h4,
h5 {
  color: #414c68;
}

.artical-right a:hover,
.footer-bottom a:hover,
.footer-bottom-end a:hover,
.footer-bottom-para a:hover,
.footer-middle li :hover,
.footer-middle-para a:hover,
.footer-top-para a:hover,
.footer-top-para-right a:hover,
.our-center-section h4,
.popular-para a:hover,
.video-icon,
.video-para,
h2 span,
h3 span {
  color: #224a98;
}

.ielts-coaching-right h4,
b,
h1,
h2,
h3,
strong {
  font-weight: 700;
}

.apply-now,
h1,
h2,
h3,
h4,
h5,
h6,
li.dropdown,
ul.main-nav li a {
  font-family: Poppins, sans-serif;
}

.popular button.owl-next::after,
.studyplan-section {
  background-position: center;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 43px;
  margin-bottom: 25px;
}

.about-left ul,
.course h2,
.dream-section h2,
.event-section h2,
.footer-copywrite p,
.hear-section h2,
.reading-para p:last-child,
.studyplan-section p,
.technology-section p,
h2,
ul.main-nav {
  margin-bottom: 0;
}

h2 {
  font-size: 37px;
  line-height: 65px;
}

.about-left h2,
.discover-left h2,
.footer-middle li,
.videos-sec h2,
a.brn-round,
h3,
h4,
h5 {
  line-height: normal;
}

h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.fw-600,
.test-format td strong,
a.brn-round,
h4,
h5 {
  font-weight: 600;
}

h4 {
  font-size: 22px;
  margin: 0;
}

.bannercustomdata strong,
.dream-head p,
.event-hero-section .event-details-info,
.event-meet-section code,
h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

a.brn-round {
  font-size: 15px;
  color: #fff;
  background-color: #414c68;
  border-radius: 50px;
  padding: 12px 31px;
  display: inline-block;
}

.header-middle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.navigation-main li:hover {
  border-bottom: 2px solid #e31e24;
}

.navigation-main .dropbtn:after {
  content: "\f107";
  font-family: FontAwesome;
  font-style: initial;
  font-weight: 700;
  padding-left: 5px;
}

ul.main-nav li {
  list-style: none;
  display: inline-block;
  margin: 0;
  text-transform: capitalize;
}

li.dropdown,
ul.main-nav li a {
  text-decoration: none;
  color: #414c68;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-weight: 400;
  display: inline-block;
  cursor: pointer;
}

header {
  padding: 15px 0;
}

.footer-middle li:last-child::before,
.hear.owl-theme span,
.popular .owl-dots,
.popular-para h5 br,
button.menu-toggle,
button.owl-next span,
button.owl-prev span {
  display: none;
}

.apply-now {
  background-color: #e31e24;
  border-radius: 10px;
  padding: 10px 15px;
  text-transform: uppercase;
  border: none;
  width: auto;
  margin-top: 25px;
}

.apply-now a {
  color: #fff !important;
  text-decoration: none;
  font-weight: 500;
}

.students span,
.students-right p,
.training-top h5 span {
  font-weight: 400;
}

.apply-now:hover {
  background-color: #224a98;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  margin-left: -15px;
  background-color: #fff;
  width: 225px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.benner,
.dream-para,
.future-section,
.future-section-left {
  position: relative;
}

.dropdown-content a {
  margin: 0 5px;
  padding: 12px 0;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #ddd;
  width: 215px;
  text-transform: capitalize;
}

.dropdown-content a:hover {
  background-color: #f7f7f7;
}

.dropdown:hover .dropdown-content,
.hear.owl-theme {
  display: block;
}

ul.main-nav li.apply-now a {
  color: #fff;
  border-radius: 10px;
}

.studyplan-section {
  background-image: url(./assets/image/background.png);
  margin-bottom: 160px;
  padding-top: 70px;
}

.studyplan-section .plan {
  margin-top: 60px;
}

.students {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

.con-center,
.course-icon,
.course-para,
.newsletter-para,
.student-media,
.students-left,
.video-icon,
.video-section {
  display: flex;
  align-items: center;
}

.students span {
  color: #e31e24;
  font-size: 14px;
  line-height: 23px;
}

.students-left {
  border-radius: 14%;
  height: 57px;
  width: 57px;
  text-align: center;
  margin: 0;
  justify-content: center;
}

.course-about,
.students-right {
  margin-left: 0;
  padding-left: 10px;
}

.blog-section .artical-right h5,
.students-para {
  padding-top: 5px;
}

.video-icon {
  font-size: 45px;
  background-color: #d3dbea;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  justify-content: center;
  margin-right: 0;
}

.video-section {
  margin: 25px 0 0 15px;
}

.video-para {
  margin-left: 0;
  padding-left: 10px;
  text-transform: capitalize;
  font-weight: 500;
}

.expert-section h5:hover,
.expert-section p:hover,
.video-para :hover {
  color: #e31e24;
  cursor: pointer;
}

.future-para {
  background-color: #f4f7fc;
  border-radius: 25px;
  padding: 40px;
}

.future-section-right img {
  top: -54px;
  position: absolute;
  right: 142px;
  z-index: -1;
}

.future-section-left img {
  border-radius: 30px;
  max-width: 100%;
  position: absolute;
  bottom: -300px;
}

.future-section-right span {
  color: #e31e24;
  font-size: 50px;
  font-weight: 300;
}

.future-section-right h2 {
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  font-size: 35px;
}

.future-section-right p,
.reading-para p,
.training-top h5 {
  margin-bottom: 25px;
}

.future-section-right h5 {
  font-weight: 700;
  background-image: linear-gradient(to right, #2d4892, #d7212b);
  color: #fff;
  border: none;
  margin: 0;
  border-radius: 50px;
  padding: 10px 50px;
  display: inline-block;
}

.dream-img-large img,
.popular.owl-theme {
  display: block !important;
}

.dream.owl-theme .item {
  padding: 0 15px 10px;
}

.dream.owl-theme .owl-dot.active span,
.hear.owl-theme .owl-dot.active span {
  border-radius: 30px;
  background: #224a98;
  padding-left: 15px;
  padding-right: 15px;
}

.dream .owl-item img {
  display: inline;
  width: auto;
}

.dream-section {
  text-align: center;
  padding: 70px 0 50px;
}

.event-section {
  text-align: center;
  padding: 30px 0;
}

.dream-para p,
.search-red {
  text-align: left;
}

.dream-para {
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 10px 0;
}

.dream-img-large img {
  border-radius: 10px;
  max-width: 100%;
  width: 100% !important;
}

.dream-img-nation {
  left: 30px;
  position: absolute;
  top: -25px;
  z-index: 1;
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.dream-img-nation img {
  display: inline-block;
  width: 100% !important;
  object-fit: cover !important;
}

.dream-data-nation {
  position: relative;
  width: 100%;
}

.dream-para h5 {
  text-align: center;
  padding: 10px 0 0 20px;
}

.dream-para p {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
}

.search-red img,
.shortlist-red img {
  padding-right: 5px;
}

.search-red a,
.shortlist-red a {
  color: #e31e24 !important;
  text-decoration: none !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
}

.search-red a:hover,
.shortlist-red a:hover {
  color: #224a98 !important;
}

.search-red {
  padding-bottom: 20px;
  padding-left: 20px;
}

.search-option p,
ul {
  padding-left: 0;
}

.education-section,
.service-section,
.summer-proccess,
.summer-range,
.videos-sec {
  background-color: #f4f7fc;
  padding: 50px 0;
}

.popular-para h5,
.service-right h5 {
  padding-top: 10px;
}

.service-right .col-sm-12.col-md-6.col-lg-6 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.service-half {
  background-color: #fff;
  padding: 30px 20px;
  height: 100%;
}

.popular .owl-nav.disabled {
  display: revert;
}

.popular button.owl-next::after {
  content: "";
  height: 35px;
  background-color: #fff;
  width: 35px;
  font-family: FontAwesome;
  color: #224a98;
  font-size: 35px;
  position: absolute;
  right: -5px;
  top: 50%;
  border-radius: 50%;
}

.hear button.owl-next::after,
.hear button.owl-next::before {
  width: 43px;
  height: 22px;
  font-family: FontAwesome;
  font-size: 35px;
  content: "";
  background-repeat: no-repeat;
  top: 50%;
}

.popular .owl-item img {
  width: auto;
  margin-left: 0;
  margin-top: 0;
  display: inline-block;
}

.popular .owl-item .popular-para img {
  margin-top: -50px;
}

.popular .item {
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.faq-section .faq-title,
.popular-section-head {
  text-align: center;
  margin-bottom: 40px;
}

.popular-section-head h4 {
  font-weight: 300;
  line-height: normal;
}

.popular-section {
  padding: 60px 0 0;
}

.popular-half {
  padding: 20px;
  background: #fff;
  margin-top: 35px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  height: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.popular-para img {
  border-radius: 50px;
}

.popular-para i.fa.fa-star {
  padding-right: 5px;
  color: #ffc000;
  padding-left: 20px;
}

.popular-para bdi {
  padding-left: 20px;
}

.popular-para span {
  color: #d0d2d7;
  padding-left: 5px;
}

.popular-para li {
  list-style: none;
  padding-left: 25px;
  background-position: left;
  line-height: 28px;
}

.center-location a,
.footer-section a,
.popular-para a {
  text-decoration: none;
  color: #414c68;
}

.shortlist-red {
  padding-bottom: 10px;
  padding-top: 10px;
}

.expert-section {
  padding: 60px 0 90px;
}

.box-modal .imageBox,
.course,
.education-left,
.event-photos-carosoul h2,
.expert-head,
.expert-pic,
.message-para h2,
.message-para h4,
.spinner-modal .imageBox,
.test-format-camp,
.training-top {
  text-align: center;
}

.expert-head h4 {
  color: #e31e24;
  margin-bottom: 0;
  font-weight: 300;
  text-transform: uppercase;
  line-height: normal;
}

.expert-pic1 {
  text-align: center;
  max-width: 80%;
  height: 90%;
  margin: auto auto 50px;
}

.course-section-left {
  display: flex;
  padding-bottom: 50px;
}

.course-left {
  padding-right: 20px;
}

.course-align .course-right {
  text-align: right;
  padding-right: 20px;
}

.careerline {
  padding: 45px 0 70px;
  background-color: #f4f7fc;
  border-radius: 10px;
}

.careerline .carreline-para {
  padding-left: 40px;
}

.carreline-para li {
  padding-bottom: 15px;
  list-style: none;
  position: relative;
  padding-left: 20px;
}

.admission-para h2 {
  font-weight: 300;
  text-align: center;
}

.carreline-para li::before {
  content: "";
  background-color: #224a98;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 0;
}

.admission-para .apply-now,
.modal-form-inquiry .apply-now {
  margin-top: 0;
  width: 100%;
  border-radius: 5px;
  padding: 11px 20px;
  color: #fff;
  font-family: Poppins !important;
  font-weight: 500;
  box-shadow: 0 0 20px rgb(227 30 36 / 20%);
}

.admission-para input,
.admission-para select,
.admission-para textarea {
  margin: 0 0 10px;
  border: none;
  padding: 7px 15px;
  border-radius: 5px;
  text-transform: capitalize;
  height: 47px;
  font-family: Poppins;
  color: #777;
  width: 100%;
}

.admission-para textarea {
  height: 119px;
  width: 100%;
}

.admission-para {
  padding-right: 40px;
  position: relative;
  padding-left: 25px;
}

.admission-dot-pic img {
  position: absolute;
  right: -57px;
  top: -101px;
}

.course-section {
  padding: 60px 0 30px;
}

.course-section p {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
}

.course-about p {
  font-size: 15px;
}

.course p {
  padding-bottom: 15px;
}

.course-para {
  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 8%);
  padding: 15px 10px 15px 15px;
  margin-bottom: 25px;
  border-radius: 5px;
}

.box-images,
.student-countrylist {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
}

.course-para p {
  margin-bottom: 0;
  margin-top: -3px;
}

.course-about h5 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 16px;
}

.course-icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin: 0;
  font-size: 18px;
  text-align: center;
  justify-content: center;
}

.hear .owl-nav.disabled {
  display: none;
  display: revert;
}

.hear button.owl-next::before {
  color: #224a98;
  position: absolute;
  left: 40px;
}

.hear button.owl-next::after {
  color: #224a98;
  position: absolute;
  right: 40px;
}

.hear.owl-theme .owl-dots {
  margin-top: -30px;
}

.hear-sectionpara::before {
  content: "";
  background-color: #eb54c9;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 135px;
}

.css-dot::before,
.hear-sectionpara::after {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  position: absolute;
  content: "";
}

.hear-sectionpara::after {
  background-color: #c854eb;
  left: 160px;
  bottom: 20px;
}

.css-dot::before {
  background-color: #0779ff;
  right: 160px;
}

.css-dot::after {
  content: "";
  background-color: #fff500;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: 120px;
  bottom: 15px;
}

.hear-section {
  text-align: center;
  padding: 70px 0;
}

.hear-sectionpara h5 {
  padding-top: 10px;
  margin-bottom: 0;
  color: #e31e24;
}

.testiomnial-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 10px solid #fff;
}

.testiomnial-img {
  overflow: hidden;
  display: inline-block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px dashed red;
}

.Iframe-className,
.artical,
.artical img,
.artical-left,
.blog-listing,
.reading,
.reading-pic {
  border-radius: 10px;
}

.Newsletter,
.footer-middle,
.footer-middle-para,
.footer-top {
  border-bottom: 1px solid #cfd6ea;
}

.hear-section p {
  padding-bottom: 25px;
  font-size: 18px;
  font-weight: 400;
}

.hear-sectionpara p {
  margin-top: 25px;
  font-size: 15px;
  font-weight: 400;
}

.happening-section {
  background-color: #f4f7fc;
  padding: 45px 0;
}

.happening-section h2 {
  text-align: center;
  padding-bottom: 30px;
}

.happening-section h4 {
  line-height: 65px;
  font-weight: 700;
}

.artical {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #fff;
  padding-left: 10px;
}

.artical-right {
  padding: 20px;
  width: 100%;
}

.artical-right h5 {
  line-height: 23px;
}

.artical-right span {
  font-size: 13px;
}

.artical-right a {
  color: #e31e24;
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Poppins, sans-serif;
}

.top-features h5,
.training-top h5,
.training-top p {
  font-family: "Open Sans";
}

.btn .apply-now {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 15px;
}

.ad-align,
.faq-section-study,
.news-section.btn {
  padding: 0;
}

.artical-section .apply-now {
  margin-top: 15px;
  padding-right: 25px;
  padding-left: 25px;
}

.Newsletter {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 65px;
}

.Newsletter form {
  max-width: 570px;
  background-color: #fff;
  border-radius: 15px;
  margin: 0 auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
}

.Newsletter input {
  width: 100%;
  padding: 15px;
  border: none;
  background-color: transparent;
}

.artical-left img,
.benner img,
.blogopics img,
.mewsletter-input {
  width: 100%;
}

.newsletter-para .apply-now {
  margin-top: 0;
  margin-right: 5px;
  padding-left: 30px;
  padding-right: 30px;
}

.event-footer,
footer {
  background-color: #dfe6f9;
}

.footer-section li {
  list-style: none;
  line-height: 30px;
}

.advising-section h4,
.blog-section,
.footer-top img,
.technology-section h4 {
  padding-bottom: 10px;
}

.footer-top {
  padding: 50px 0 36px;
}

.footer-top-para-right a {
  color: #414c68;
  padding-left: 10px;
}

.footer-bottom,
.footer-bottom-para,
.footer-top-para {
  border-bottom: 1px solid #cfd6ea;
  padding: 35px 0;
}

.footer-top-para-right p {
  margin-bottom: 0;
  padding-bottom: 5px;
}

.footer-socialmedia a {
  height: 34px;
  width: 34px;
  background-color: #9baee1;
  border-radius: 50%;
  display: flex;
  color: #fff;
  align-items: center;
  margin-left: 2px;
  margin-right: 2px;
  justify-content: center;
  font-size: 18px;
  text-decoration: none;
}

.advantage-para p,
.advising-section .row,
.center-location a img,
.center-para p,
.footer-bottom-end ul,
.footer-middle ul,
.technology-section .row {
  margin: 0;
}

.footer-socialmedia :hover {
  background-color: #fff;
  color: #224a98;
}

.footer-socialmedia {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.footer-middle li {
  display: inline-block;
  padding-left: 15px;
  padding-right: 5px;
  position: relative;
}

.footer-middle li::before {
  content: "";
  background-color: #414c68;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: -7px;
}

.footer-middle {
  padding: 25px 0;
}

.footer-middle-para {
  padding: 30px 0;
}

.container.footer-bottom-end li {
  display: inline-block;
  padding: 0 5px;
}

.footer-bottom-end {
  padding: 20px 0;
}

.footer-bottom-end li {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

.footer-service {
  background-color: #184293;
}

.about-section,
.advising-section,
.blog-bottom span,
.blog-listing,
.message-section,
.summr-def-col,
.technology-section {
  background-color: #f4f7fc;
}

.footer-privacy {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.event-footer .footer-top-para-right a,
.footer-service a {
  text-decoration: none;
}

.footer-copywrite {
  margin-left: 0;
  padding: 10px 0;
  color: #fff;
}

.policy {
  margin-right: 0;
}

.policy a {
  color: #fff;
  padding: 0 5px;
}

.expert-section .row {
  align-items: center;
  padding-top: 20px;
}

.blog-section .blog-boxes .col-sm-12,
.careerline h2,
.training-top p {
  margin-bottom: 20px;
}

.Newsletter h3 {
  margin-bottom: 5px;
}

.container-fluid {
  max-width: 1900px;
}

.header-bottom {
  background-image: linear-gradient(to right, #2e4993, #db2029);
  color: #fff;
  text-transform: capitalize;
  margin-top: 112px;
}

.header-bottom p {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  margin-bottom: 0;
}

.blog-bottom img,
.input-chacked label {
  padding-left: 10px;
}

.message-para .footer-socialmedia,
.our-center-section .row {
  justify-content: center;
}

.our-center-section h2 {
  text-align: center;
  text-transform: capitalize;
  padding-top: 10px;
  padding-bottom: 10px;
}

.addition-perk-half img,
.our-center-section .addrss-cols {
  margin-bottom: 15px;
}

.center-para {
  border: 1px solid #d9d9d9;
  padding: 20px;
  height: 100%;
  border-radius: 5px;
}

.center-location {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.center-location span {
  color: #ff4646;
}

.center-location img {
  padding-right: 10px;
  margin-top: 5px;
}

.message-section {
  padding-top: 45px;
  padding-bottom: 55px;
  margin-bottom: 70px;
  margin-top: 70px;
}

.message-para p {
  text-align: center;
  margin-bottom: 35px;
}

.admission-para input#country {
  width: 20px;
  height: 20px;
  margin-bottom: 0;
  cursor: pointer;
}

.input-phone {
  display: flex;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: 96% 50%;
}

.input-chacked {
  align-items: center;
  display: inline-flex;
  min-width: 25%;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  padding-right: 10px;
}

.input-phone input {
  width: 50%;
  margin-right: 5px;
  margin-bottom: 15px;
}

.input-phone select {
  width: 50%;
  margin-left: 5px;
  border: none;
  height: 47px;
  cursor: pointer;
  padding-left: 10px;
  color: #414c68;
}

.get-in-touch .apply-now {
  margin-top: 10px;
}

.blog-section-heading,
.videos-first {
  padding: 50px 0;
}

.blog-btn button.apply-now {
  padding: 10px 30px;
}

.about-benner-right img,
.blog-section img,
.reading-pic img,
img {
  max-width: 100%;
}

.blog-bottom span {
  padding: 10px;
  border-radius: 5px;
  margin: 5px;
}

.blog-bottom span:hover {
  color: #fff;
  background-color: #e31e24;
}

.blog-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dfe6f9;
  padding-top: 25px;
  padding-bottom: 65px;
}

.discover-section .row,
.summercamp-first .row,
.university-para {
  align-items: center;
}

.blog-bottom img {
  height: 20px;
}

.benner-para {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  right: 0;
}

.about-right h2,
.benner-para h2,
.students-pic h5,
.students-pic h6,
.technology-para h4 {
  color: #fff;
}

.discover-section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.top-light {
  font-size: 18px;
  font-weight: 300;
  color: #e31e24;
}

.discover-para {
  padding-top: 40px;
}

.discover-para p {
  position: relative;
  padding-left: 75px;
  margin-bottom: 20px;
  font-weight: 500;
}

.discover-para p img {
  position: absolute;
  left: 10px;
}

.about-left {
  padding-top: 45px;
  padding-bottom: 45px;
}

.about-left li {
  list-style: none;
  line-height: 22px;
  padding-bottom: 15px;
}

.about-right li::before {
  border-radius: 0;
  background-color: #e94b51;
}

.about-right {
  background-color: #224a98;
  color: #fff;
  padding-left: 55px;
  height: 100%;
  position: relative;
  padding-top: 75px;
}

.about-right::after {
  content: "";
  background-color: #224a98;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 100%;
  top: 0;
}

.university-section {
  padding-top: 55px;
  padding-bottom: 100px;
}

.university-head {
  text-align: center;
  font-weight: 700;
}

.university-para {
  display: flex;
  padding-top: 35px;
  justify-content: center;
  max-width: 870px;
  margin: 0 auto;
  gap: 20px;
}

.careermb,
.top-features {
  margin-bottom: 60px;
}

.advantage-section {
  background-color: #f4f7fc;
  padding-top: 45px;
}

.university-bottom {
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.university-bottom-para {
  display: inline-flex;
  align-items: center;
  text-align: center;
}

.university-bottom span {
  background-color: #faebd7;
  font-weight: 700;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  border: 1px dashed #224a98;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.university-bottom-para img {
  padding: 0 10px;
}

.advantage-section .row,
.under-graduate .row {
  margin-right: 40px;
  margin-left: 40px;
}

.advantage-section h2,
.under-graduate h2 {
  text-align: center;
  padding-bottom: 25px;
}

.advantage-para {
  border-left: 5px solid #84aeff;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
  font-weight: 500;
}

.under-graduate {
  background-color: #f4f7fc;
  padding-bottom: 75px;
  padding-top: 50px;
}

.under-graduate-para {
  background-color: #fff;
  height: 100%;
  padding: 25px 15px;
}

.annual-cost-section {
  padding: 65px 0;
}

.annual-section {
  border-radius: 10px;
  border: 1px solid #e31e24;
}

.annual-section h4 {
  background-color: #e31e24;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 30px;
  font-weight: 700;
}

.annual-section p {
  border-bottom: 1px solid #cccc;
  margin: 0 30px;
  line-height: 27px;
  padding-bottom: 20px;
}

.annual-section h5 {
  margin: 0 30px;
  padding-top: 30px;
  padding-bottom: 10px;
}

.work-section {
  padding-left: 50px;
}

.work-section h4 {
  padding-bottom: 15px;
  font-weight: 700;
}

.work-section p {
  line-height: 28px;
  padding-bottom: 15px;
}

.work-section li {
  line-height: 28px;
  padding-bottom: 5px;
}

.guidence-section {
  padding-bottom: 90px;
  padding-top: 60px;
  background-color: #f4f7fc;
}

.techno,
.technology-para {
  background-color: #e31e24;
  height: 100%;
}

.guidence-section h3 {
  font-size: 26px;
  margin-bottom: 0;
  padding-bottom: 25px;
  line-height: normal;
}

.guidence-left h5 {
  color: #e31e24;
  font-weight: 700;
  line-height: 27px;
}

.guidence-left p {
  margin-bottom: 0;
  line-height: 28px;
}

.guidence-para {
  padding-top: 30px;
}

.guidence-right p {
  position: relative;
  padding-left: 65px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.guidence-right img {
  position: absolute;
  left: 0;
}

.canadian-section {
  text-align: center;
  padding-bottom: 60px;
  padding-top: 50px;
  line-height: 45px;
}

.about-benner {
  padding-top: 64px;
  padding-bottom: 110px;
}

.about-benner-left {
  padding-top: 15px;
}

.about-benner-left h2 {
  padding-bottom: 20px;
  line-height: 53px;
}

.about-benner-left p {
  font-weight: 500;
}

.about-top h2 {
  font-weight: 300;
  color: #e31e24;
  padding-bottom: 0;
}

.education-section h2 {
  line-height: 43px;
  padding-bottom: 25px;
}

.education-left img {
  padding-bottom: 15px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.education-left span {
  font-weight: 300;
  font-size: 22px;
  color: #e31e24;
}

.mission-section {
  padding-top: 70px;
  padding-bottom: 100px;
}

.mission-section h2 {
  line-height: 53px;
}

.advising-center {
  padding: 45px 30px;
}

.event-uni-section .uni-list .uni-img-div img,
.pic-set {
  height: 100%;
}

.pic-set img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
}

.technology-section h5 {
  font-weight: 300;
  margin-bottom: 0;
  color: #fff;
  padding-top: 60px;
}

.techno {
  border: 10px solid #e31e24;
}

.technology-para {
  text-align: center;
  color: #fff;
  width: 100%;
  border: 1px solid #fff;
  padding: 20px;
}

.technology-para .apply-now {
  background-color: #fff;
  margin-bottom: 45px;
  padding-left: 30px;
  padding-right: 30px;
}

.technology-para .apply-now a {
  color: #e31e24 !important;
}

.infrastructure-section {
  padding-bottom: 100px;
  padding-top: 90px;
}

.infrastructure-right {
  text-align: right;
  position: relative;
}

.infrastructure-right-para img {
  top: 30px;
  left: -60px;
  position: absolute;
  border: 10px solid #fff;
  box-shadow: 5px 5px 5px 5px #e3e3e3;
}

.infrastructure-left {
  margin-right: 30px;
  padding-right: 50px;
}

.infrastructure-left li {
  list-style: none;
  position: relative;
  line-height: 23px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.infrastructure-left li::before {
  width: 10px;
  background-color: #224a98;
  content: "";
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 0;
}

.popular-extra {
  padding-bottom: 50px;
}

.highlights-left li::before,
.ielts-coaching-left li::before,
.prepration-right li::before {
  content: "-";
  padding-right: 10px;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 0;
}

.highlights-left li,
.ielts-coaching-left li,
.prepration-right li {
  list-style: none;
  font-weight: 500;
  line-height: 28px;
  padding-left: 20px;
  position: relative;
  display: block;
}

.ielts-coaching-left {
  padding-top: 70px;
}

.ielts-coaching-right input,
.ielts-coaching-right textarea {
  border: 1px solid #ccc;
  width: 100%;
}

.ielts-coaching-right {
  text-align: center;
  background-color: #fff;
  margin-top: -160px;
  padding: 25px 0;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
  max-width: 450px;
  float: right;
}

.ielts-coaching-right span {
  display: inline-flex;
  align-items: center;
}

.ielts-coaching-right h5 {
  padding-top: 25px;
}

.ielts-coaching-right form h4 {
  color: #e31e24;
  font-weight: 700;
}

.ielts-coaching-right form {
  padding: 30px 30px 0;
}

.prepration {
  padding-top: 50px;
  padding-bottom: 45px;
}

.prepration-left {
  padding-right: 30px;
}

.prepration-left img {
  max-width: 100%;
  border-radius: 10px;
}

.prepration-right h3 {
  font-size: 28px;
}

.prepration-right span,
ul.main-nav li.active-a a {
  color: #e31e24;
}

.top-features {
  background-color: #e3eaf6;
  border-radius: 10px;
  padding: 45px 40px;
  margin-top: 50px;
}

.top-features span,
.training-section h3 span {
  color: #e31e24;
  text-transform: uppercase;
}

.top-features h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.tp-features {
  padding-top: 36px;
}

.test-format {
  padding-top: 45px;
  padding-bottom: 60px;
}

.test-format h3 {
  font-weight: 700;
  font-size: 28px;
}

.test-format table {
  border: 1px;
  width: 100%;
}

table thead tr:first-child {
  background-color: #224a98;
  color: #fff;
  font-weight: 600;
}

.test-format th,
td {
  padding: 10px 20px;
}

.training-section h3 {
  font-size: 28px;
  margin-bottom: 0;
}

.reading {
  display: flex;
  background-color: #f4f7fc;
  padding: 10px;
  margin-top: 10px;
  height: 100%;
}

.training-section .row .col-sm-12 {
  margin-bottom: 22px;
}

.reading-pic {
  width: 212px;
  background-color: #ffe5d4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reading-para {
  padding-left: 20px;
  padding-top: 10px;
}

.training-section {
  padding-bottom: 25px;
}

.artical-left {
  width: 210px;
  overflow: hidden;
}

.careerline-summer {
  margin-bottom: 100px;
}

.process-cycle {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.process-cycle-item {
  width: 20%;
  padding: 10px 30px 10px 0;
  position: relative;
}

.process-cycle-item p {
  position: absolute;
  left: 42px;
  background-color: #fff;
  top: 18px;
  padding: 10px;
  border-radius: 9px;
  min-height: 105px;
  display: flex;
  align-items: center;
  font-weight: 600;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: calc(100% - 57px);
  line-height: normal;
}

span.process-number {
  display: inline-block;
  width: 100%;
  text-align: left;
  line-height: 100px;
  font-weight: 700;
  background-color: #224a98;
  color: #fff;
  padding: 10px 14px;
  font-size: 30px;
  border-radius: 10px;
}

.easy-that {
  font-weight: 600;
  color: red;
}

th.camp-time-th {
  min-width: 160px;
}

.addition-section {
  text-align: left;
  padding: 50px 0;
}

.addition-perk-half {
  text-align: left;
  background-color: #fff;
  height: 100%;
  padding: 20px 20px 20px 30px;
  border: 1px solid #dde8ff;
  border-radius: 10px;
  border-left: 10px solid #224a98;
}

.addition-section .col-sm-6.col-md-6 {
  margin-bottom: 30px;
}

.addition-perk-half p {
  margin: 0;
  font-weight: 500;
}

.summer-range ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
}

.summer-range ul li {
  padding: 10px;
  width: 20%;
  list-style: none;
}

.camp-range-li img {
  max-height: 80px;
  display: block;
}

.camp-range-li span {
  font-weight: 600;
  margin-top: 10px;
  display: block;
}

.summercamp-first img {
  max-width: 100%;
  border-radius: 50%;
  border: 2px dashed #224a98;
  height: 500px;
  width: 500px;
  display: block;
  margin: 0 auto;
}

.summercamp-first {
  padding: 60px 0;
}

.summercamp-def-col {
  background-color: #d1def5;
  color: #000;
  padding: 50px;
}

.summercamp-def-col li {
  margin-bottom: 10px;
}

.summer-cycle-left ul.infrastructure-left {
  padding-right: 0;
  margin-right: 0;
}

.summer-cycle-twopic {
  display: flex;
  position: relative;
}

.summer-cycle-twopic img {
  width: 90%;
  height: auto;
}

.summer-cycle-twopic img.twopic-last {
  position: absolute;
  right: 0;
  top: 48%;
  border-radius: 50%;
  border: 10px solid #fff;
  width: 450px;
  height: 450px;
  object-fit: cover;
}

.sum-benifits {
  background: #fff;
  background: linear-gradient(360deg, #fff 0, rgb(240 245 255) 100%);
  padding: 50px 0;
}

.videos-first h2 {
  text-align: center;
  margin-bottom: 20px;
}

.videos-first iframe {
  border-radius: 10px;
  width: 100%;
  height: 234px;
  margin-bottom: 20px;
}

.videos-sec iframe {
  width: 100%;
  border-radius: 20px;
}

button.accordion-button {
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 16px 20px;
  background-color: #dfe6f9 !important;
  margin-top: 16px;
  color: #000 !important;
  font-weight: 500;
}

.event-faqs button.accordion-button {
  padding: 0 20px;
}

.accordion-item {
  border: 0;
  border-bottom: 0;
  border-radius: 0;
}

.accordion-body {
  background-color: #f4f7fc;
  border: 0;
  padding: 30px 60px;
  color: #414c68;
}

.accordion-button::after {
  order: -1;
  margin-right: 10px;
  margin-left: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  content: "+";
  background-image: none !important;
  text-align: center;
  font-size: 26px;
  font-weight: 300;
}

.accordion-button:not(.collapsed)::after {
  content: "-";
  background-image: none !important;
  text-align: center;
}

.faq-section {
  padding: 50px 0 100px;
}

div#accordionExample {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

button.accordion-button.collapsed {
  background-color: #f8faff !important;
}

.modal-header button.close {
  background-color: transparent;
  border: 0;
  width: auto;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 30px;
  margin: 0;
  padding: 10px 20px;
  line-height: normal;
}

.modal-header {
  border: 0;
  padding: 0;
}

.modal-content {
  padding: 30px;
}

.modal-dialog {
  width: 600px;
  max-width: calc(100% - 20px);
}

.modal-form-inquiry input {
  margin: 0 0 10px;
  border: 1px solid #ddd;
  padding: 7px 15px;
  border-radius: 5px;
  text-transform: capitalize;
  height: 47px;
  font-family: Poppins;
  color: #777;
  width: 100%;
}

.foundation-input-para {
  display: flex;
  width: 100%;
  gap: 10px;
}

.custom-banner-home {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.box-images {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.box-images.leaf {
  left: 40px;
  top: 20%;
}

.box-images.bank {
  right: 40px;
  top: 0;
  width: 80px;
  height: 80px;
}

.box-images img {
  max-height: 35px;
}

.box-images.bank img {
  max-height: 25px;
}

.box-images.microsope {
  right: 40px;
  bottom: 80px;
}

.bannercustomdata {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  padding: 30px 10px;
  background-color: #cbd2d8;
  border: 5px solid #fff;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #370448;
  font-size: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
}

.bannercustomdata h4 {
  font-size: 18px;
  color: #370448;
}

span.redtext {
  color: #e31e24;
  font-weight: 600;
}

.student-countrylist {
  width: auto;
  border-radius: 10px;
  background-color: #fff;
  position: absolute;
  right: 30px;
  top: 40%;
  padding: 10px 10px 15px;
}

.student-countrylist p {
  display: block;
  margin: 0 0 7px;
  color: #066ac9;
}

.student-countrylist img {
  max-height: 35px;
  margin-right: -13px;
  border: 2px solid #fff;
  border-radius: 50%;
}

span.pluscounty {
  width: 35px;
  height: 35px;
  background-color: #066ac9;
  color: #fff;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #fff;
  line-height: 29px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.students-pic img {
  max-height: 500px;
}

.students-pic {
  position: absolute;
  bottom: 190px;
  left: 20%;
  right: 0;
  margin: 0 auto;
}

.students-pic h4 {
  margin-left: 20px;
  margin-bottom: 8px;
  color: #fff;
}

.students-pic .reg-men {
  color: #e5aaaa;
}

.students-pic .last-text {
  margin-right: 10px;
}

.bannercustomdata img {
  max-height: 60px;
  margin-bottom: 10px;
}

.owl-carousel .owl-item .custom-banner-home img {
  display: inline-block;
  width: auto;
}

.custom-banner-home {
  padding: 70px 70px 100px;
}

.loading-spinner-new-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.loading-spinner-new {
  border: 3px solid #3498db;
  border-bottom: 3px solid #f3f3f3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: 1s linear infinite spin;
}

.event-banner-section {
  height: 600px;
  width: 100%;
  color: #fff;
  background-image: url("./assets/image/weffrbeventbanner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 -200px 200px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.event-banner-section.surat-banner {
  background-image: url("./assets/image/weffrbeventbannerSurat.jpg");
}

.event-banner-section.anand-banner {
  background-image: url("./assets/image/weffrbeventbannerAnand.jpg");
}

.event-banner-section .event-banner-bottom {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.event-banner-section .event-banner-bottom .event-banner-btn {
  text-decoration: none;
  padding: 10px 15px;
  color: #fff;
  background: #a61115;
  border-radius: 30px;
  margin: 10px 0;
  transition: 0.3s;
  box-shadow: 1px 1px 5px #000;
}

.event-banner-section .event-banner-bottom .event-banner-btn:hover {
  background: #bb3438;
  box-shadow: none;
}

.event-hero-section {
  padding: 50px 10px 30px;
  color: #000;
}

.event-hero-section .event-hero-label,
.event-meet-section .event-meet-label,
.event-testimonials .event-testimonials-label,
.event-uni-section .event-uni-label {
  position: relative;
  display: inline;
  color: #000;
}

.event-hero-section .event-hero-label::before,
.event-meet-section .event-meet-label::before,
.event-testimonials .event-testimonials-label::before,
.event-uni-section .event-uni-label::before {
  content: " ";
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 8px;
  border-radius: 10px;
  width: 20px;
  background: linear-gradient(to right, red, orange);
  transition: 0.3s;
}

.event-hero-section .event-hero-label:hover::before,
.event-meet-section .event-meet-label:hover::before,
.event-testimonials .event-testimonials-label:hover::before,
.event-uni-section .event-uni-label:hover::before {
  width: 100%;
  background: linear-gradient(to right, red, red, orange);
}

.event-meet-section {
  padding: 50px 10px;
  color: #000;
  background: #f5f5f5;
}

.event-meet-section p {
  color: #de5600;
  font-weight: 700;
}

.event-meet-section ul.custom-list li::marker {
  content: "◙ ";
  color: #de5600;
  font-size: 18px;
}

.event-meet-section ul.custom-list li,
.event-reg-form {
  padding: 10px;
}

.event-attend-section {
  padding: 10px;
  background: linear-gradient(90deg, #bb3438, #e0b71c);
}

.event-attend-section img {
  height: 60px;
  margin: 10px;
}

.event-attend-section .box {
  padding: 10px;
  height: 100%;
  color: #fff;
}

.event-attend-section .box h5 {
  font-size: 20px;
  line-height: 32px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #fff;
}

.event-attend-section .box p {
  font-size: 14px;
  text-align: justify;
}

.event-uni-section {
  padding: 50px 10px;
  color: #000;
}

.event-testimonials .testimonial-hightlight,
.event-uni-section .uni-count {
  color: #bb3438;
  position: relative;
  z-index: 1;
}

.event-testimonials .testimonial-hightlight:before,
.event-uni-section .uni-count:before {
  z-index: -11;
  content: "";
  background: #ffd1d2c4;
  width: 100%;
  height: 16px;
  position: absolute;
  left: 0;
  bottom: 3px;
}

.event-uni-section .uni-list .uni-img-div {
  height: 70px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
}

/* .event-uni-section .uni-list .uni-img-div:hover .img-fluid {
    transform: scale(1.2);
    transition: .5s;
} */
.event-testimonials {
  /* background: #f5f5f5; */
  color: #000;
}

.event-testimonials .slick-next,
.event-testimonials .slick-prev {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.event-testimonials .testimonials {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 10px gray;
  cursor: pointer;
}

.event-testimonials .testimonials:hover .play-button {
  background-color: rgba(0, 0, 0, 0.7);
}

.event-testimonials .testimonial-prev-arrow {
  top: -98px;
  right: 60px;
}

.event-testimonials .testimonial-next-arrow {
  top: -98px;
  right: 0;
}

.event-testimonials .testimonials-list {
  margin-top: 4px;
}

.event-testimonials .testimonials .testimonials-item-effect {
  position: absolute;
  top: -42px;
  right: -34px;
  height: 200px;
  width: 200px;
  opacity: 1;
  border-radius: 50%;
  z-index: -2;
  box-shadow: 0 0 21px #00000036, inset 0 0 40px #ffffff21;
}

.event-testimonials .testimonials .testimonials-item-top {
  height: 312px;
  text-align: justify;
  background-color: coral;
  color: #fff;
  z-index: 1;
}

.event-testimonials .testimonials .testimonials-item-top.usaevent-testimonial-top {
  height: 196px;
}

.event-testimonials .testimonials .testimonials-item-top .testimonials-item-desc {
  /* height: 281px; */
  height: 233px;
  overflow-y: scroll;
  scrollbar-width: none;
  font-family: "Lobster Two", sans-serif;
  font-size: 17px;
  letter-spacing: 1px;
  font-style: italic;
}

.event-testimonials .testimonials .testimonials-item-top .testimonials-item-desc.usaevent_testimonial {
  height: 162px;
}

.event-testimonials .testimonials .testimonials-item-bottom .textimonial-img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 12px;
  overflow: hidden;
  float: left;
}

.event-footer .footer-imgs img {
  height: 80px;
}

.event-footer .footer-socialmedia .bi,
.event-footer .footer-top-para-right .bi {
  color: #fff;
  background: #bb3438;
  padding: 5px 8px;
  border-radius: 50%;
}

.event-footer .footer-socialmedia .bi:hover {
  color: #fff;
  background: linear-gradient(45deg, #fedf22, #bb3438);
  padding: 5px 8px;
  border-radius: 50%;
}

.card-header.event-card-header {
  background: linear-gradient(45deg, #bb3438, #daa520);
  color: #fff;
}

.event-reg-form .submit-btn {
  color: #fff;
  background: #bb3438;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 5px;
  border: none;
}

.event-reg-form .submit-btn:hover {
  background: #72090d;
}

.cwc2-event-banner-section,
.cwc-event-banner-section {
  height: 800px;
  width: 100%;
  background-image: url("./assets/image/cwc/cwcHeader1502.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cwc2-event-banner-section {
  background-image: url("./assets/image/cwc/cwcHeader2.png");
}

.cwc2-event-section-5,
.cwc2-event-section-3 {
  background: #f5fafe;
  color: #275aa6;
}

.cwc-event-section-9,
.cwc-event-clgfair-section,
.cwc-event-section-6,
.cwc-event-hero-section {
  background: #d9e8ff;
  color: #275aa6;
}

.cwc2-event-section-6 {
  color: #275aa6;
}

.cwc2-event-section-5 h1,
.cwc-event-section-6 h1,
.cwc-event-section-9 h1,
.cwc-event-clgfair-section h1,
.cwc-event-hero-section h1 {
  color: #275aa6;
  font-size: clamp(25px, 4vw, 70px);
  font-weight: bold;
}

.cwc2-event-section-4 h1,
.cwc2-event-section-3 h1,
.cwc2-event-section-6 h1,
.cwc2-event-section-5 h1 {
  font-family: "Passion One", sans-serif;
  font-weight: 400;
  /* word-spacing: 5px; */
}

.cwc-event-hero-section .hero-subline {
  font-size: clamp(14px, 2vw, 30px);
}

.cwc-event-hero-section .hero-line {
  font-size: clamp(10px, 2vw, 16px);
}

.cwc2-event-section-7,
.cwc-event-goodnews-section {
  background: #275aa6;
  color: #fff;
}

.cwc2-event-section-7 h1 {
  color: #fff;
  font-size: clamp(20px, 4vw, 70px);
  font-weight: bold;
}

.cwc-event-goodnews-section h1 {
  color: #fff;
  font-size: clamp(25px, 4vw, 70px);
  font-weight: bold;
}

.cwc3-event-section-3 h1,
.cwc3-event-hero-section h1 {
  font-size: clamp(25px, 3vw, 50px);
  font-weight: bold;
}

.cwc3-event-section-4 h1 {
  font-size: clamp(25px, 3vw, 50px);
  font-weight: bold;
  color: black;
}

.cwc3-event-section-3 img {
  height: 100px;
}

.cwc3-event-section-3 h3 {
  font-size: clamp(15px, 3vw, 25px);
  line-height: clamp(20px, 3vw, 40px);
  font-weight: 500;
  margin-bottom: 5px;
}

.cwc3-event-section-3 p {
  font-size: clamp(14px, 2vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
  margin: 0;
}

.cwc3-event-section-4 {
  background: #e65272;
  color: #fff;
}

.cwc3-event-section-4 .influencerButton {
  width: 248px;
  height: 76px;
  background-color: #275aa6;
  border-radius: 28px;
  border: none;
  display: flex;
  align-items: center;
}

.cwc3-event-section-4 .influencerButton .influencerDetails {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
}

.cwc3-event-section-4 .influencerButton .influencerDetails p:nth-child(1) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 136px;
}

.cwc3-event-section-4 .nextButton {
  height: 76px;
  width: 76px;
  border-radius: 28px;
  margin-left: 32px;
  border: none;
}



.cwc3-event-section-4 .swiper {
  margin: 0;
  max-width: 485px;
  /* max-width: 100%; */
}

.cwc3-event-section-4 .swiper .swiper-slide {
  display: flex;
  align-items: center;
}

.cwc3-event-section-4 .mobile-wrapper {
  position: relative;
  padding: 1px;
  background: black;
  border-radius: 37px;
}

.cwc3-event-section-4 .mobile-mockup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.cwc3-event-section-4 iframe,
.cwc3-event-section-4 video {
  width: 275px;
  border-radius: 37px;
  height: 474px;
}

.cwc3-event-section-4 .swiper-thumbnails {
  border-radius: 15px;
  border: 2px solid #000;
  aspect-ratio: 9/16;
  transition: transform 0.3s ease;
  width: 225px;
  cursor: pointer;
  object-fit: contain;
  background: #2c2c2c;
}

.cwc3-event-section-6 {
  background: #D9E8FF;
}

.cwc3-event-section-7 {
  background: #275aa6;
}

.cwc3-event-section-7 .accordion-item {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}

.cwc3-event-section-7 button.accordion-button.collapsed {
  background: #d9e8ff !important;
}

.cwc3-event-section-7 button.accordion-button {
  background: #d9e8ff !important;
  padding: 0 10px !important;
  margin: 0;
}

.cwc3-event-section-6 .story-card {
  transition: transform 0.2s, filter 0.2s, box-shadow 0.2s;
}

.story-card .card-wrapper {
  background-color: white;
  border-radius: 15px;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.cwc3-event-section-6 .story-card .card-wrapper:hover {
  box-shadow: 0 0 4px darkseagreen;
  transform: scale(1.03);
}

.testimonial-sec:hover .story-card .card-wrapper:not(:hover) {
  filter: blur(1px);
  opacity: 0.7;
}

.cwc3-event-section-6 p {
  font-size: clamp(14px, 2vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
  text-align: justify;
}

.cwc3-event-section-6 .story-card .story-country {
  font-size: clamp(12px, 2vw, 18px);
  line-height: clamp(20px, 2.5vw, 30px);
}

/* 
.cwc3-event-section-4 img:hover {
  transform: scale(1.05);
} */

.cwc2-event-section-7 p {
  font-size: clamp(14px, 2vw, 30px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc-event-goodnews-section .goodnews-subline {
  font-size: clamp(14px, 2vw, 30px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc3-event-hero-section p {
  font-size: clamp(14px, 2vw, 25px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc3-event-hero-section .counts-image {
  width: 50%;
  height: auto;
  margin-bottom: 20px;
  filter: drop-shadow(5px 5px 4px gray);
}

.cwc3-event-hero-section .counts-para {
  font-size: clamp(11px, 2.5vw, 18px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc-event-goodnews-section table td {
  border: 1px solid #275aa6;
  font-size: clamp(12px, 2vw, 25px);
  line-height: clamp(20px, 2.5vw, 40px);
  border-bottom: 1px solid #fff;
  padding: 15px;
}

.cwc3-event-hero-section .event-btn-wrapper {
  padding: 50px 0;
}

.cwc-nov-event {
  background-color: #275aa6;
}

.cwc-nov-event table {
  border: none;
  border-collapse: collapse;
}

.cwc-nov-event table td,
.cwc-nov-event table th {
  border: none;
  /* Removes borders from table cells */
}

.cwc-nov-event table td {
  border-bottom: 1px solid white;
}

.cwc3-event-section-5 .cwc-event-form .submit-btn,
.cwc3-event-hero-section .event-banner-btn,
.cwc-nov-event .event-form-navigation-btn {
  text-decoration: none;
  padding: 10px 15px;
  background: #e65272;
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  border-radius: 10px;
  margin: 10px 0;
  transition: 0.3s;
  box-shadow: 1px 1px 5px #000;
  cursor: pointer;
}

.cwc3-event-section-5 .cwc-event-form .submit-btn:hover,
.cwc3-event-hero-section .event-banner-btn:hover {
  background: #e65272;
  box-shadow: none;
}

.cwc-event-section-8 .event-banner-btn,
.cwc-event-goodnews-section .event-banner-btn {
  text-decoration: none;
  padding: 10px 15px;
  background: #ffe145;
  font-size: 25px;
  font-weight: 700;
  color: #275aa6;
  border-radius: 10px;
  margin: 10px 0;
  transition: 0.3s;
  box-shadow: 1px 1px 5px #000;
  cursor: pointer;
}

.cwc-event-section-8 .event-banner-btn:hover,
.cwc-event-goodnews-section .event-banner-btn:hover {
  background: #ffe771;
  box-shadow: none;
}

.cwc-event-clgfair-section p {
  font-size: clamp(14px, 2vw, 30px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc-event-clgfair-section .college-fair-sec1 {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
}

.cwc-event-section-5 {
  background: #275aa6;
  color: #fff;
}

.cwc-event-section-7 .container,
.cwc-event-section-5 .container {
  background: url("./assets/image/cwc/USA2.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}

.cwc-event-section-7 .container {
  padding-bottom: 100px;
}

.cwc-event-section-5 .hero-subline {
  font-size: clamp(15px, 3vw, 40px);
  line-height: clamp(18px, 3.5vw, 50px);
}

.cwc-event-section-5 p {
  font-size: clamp(14px, 2vw, 30px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc-event-section-5 .coffee-bean {
  position: absolute;
  right: -300px;
  top: -206px;
}

.cwc-event-section-5 .event-banner-btn {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.cwc-event-section-5 .section-4-btn {
  font-size: clamp(12px, 2vw, 30px);
  line-height: clamp(18px, 2.5vw, 50px);
  background: #e65272;
  width: fit-content;
  padding: 11px;
  border: 1px solid white;
  border-radius: 15px;
}

.cwc-event-section-7 {
  background: #275aa6;
}

.cwc-event-section-7 h1 {
  color: #fff;
  font-size: clamp(25px, 4vw, 70px);
  font-weight: bold;
}

.cwc-event-section-7 h2 {
  color: #ffe145;
  font-size: clamp(18px, 2vw, 40px);
  line-height: clamp(18px, 2.5vw, 50px);
}

.cwc-event-section-7 p {
  color: #fff;
  font-size: clamp(14px, 2vw, 30px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc-event-section-9 .right-advice,
.cwc-event-section-7 .right-advice {
  width: 40%;
}

.cwc-event-section-8 {
  background: #e65272;
  color: #fff;
}

.cwc-event-section-8 h1 {
  color: #fff;
  font-size: clamp(25px, 4vw, 70px);
  font-weight: bold;
}

.cwc-event-section-8 p {
  font-size: clamp(14px, 2vw, 30px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc2-event-section-5 img,
.cwc-event-section-8 img {
  width: 75%;
}

.cwc-event-section-8 .section-8-mentor::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #ffe145;
}

.cwc2-event-section-5 p,
.cwc2-event-section-6 p,
.cwc-event-section-9 p {
  font-size: clamp(14px, 2vw, 30px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc-event-section-9 .section-9-line {
  font-size: clamp(15px, 2vw, 32px);
  line-height: clamp(18px, 2.8vw, 50px);
}

/* YourStyles.css */
.testimonial-slide {
  height: 100%;
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0 auto;
}

.cwc2-event-section-6 .testimonial-slide {
  background: #f5fafe;
}

.testimonial-slide p {
  font-size: clamp(12px, 2vw, 20px);
  /* line-height: clamp(18px, 2.8vw, 50px); */
  color: #333;
}

.testimonial-slide h3 {
  margin-top: 15px;
  font-weight: bold;
  color: #1e5b94;
}

.cwc-event-form {
  /* max-width: 440px; */
  width: 100%;
}

.cwc-event-form .card-body {
  padding: 32px;
}

.cwc-event-form .card-title {
  font-weight: 800;
  margin-bottom: 15px;
}

.cwc-event-form .submit-btn {
  background: #e65272;
  padding: 10px 20px;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  border: none;
  border-radius: 10px;
  margin-top: 15px;
}

.cwc2-event-hero-section {
  color: #275aa6;
}

.cwc2-event-hero-section .hero-subline {
  margin-bottom: 100px !important;
}

.cwc2-event-hero-section p {
  font-size: clamp(14px, 2vw, 30px);
  line-height: clamp(20px, 2.5vw, 40px);
}

.cwc2-event-hero-section .section2-event-details {
  background: #f5fafe;
  border-radius: 30px;
  padding: 15px 30px;
  position: relative;
  box-shadow: 0 10px 20px #0000001f;
}

.cwc2-event-hero-section .section2-event-details p {
  display: flex;
  align-items: center;
}

.cwc2-event-hero-section .section2-event-details .cwc-pic {
  width: 40%;
  position: absolute;
  top: -40px;
  right: -50px;
}

.cwc2-event-section-6 h1,
.cwc2-event-section-3 h1 {
  color: #275aa6;
  font-size: clamp(25px, 4vw, 70px);
}

.cwc2-event-section-3 h3 {
  line-height: clamp(25px, 2.5vw, 30px);
}

.cwc2-event-section-3 .attend-card {
  background: rgb(39, 90, 166);
  padding: 15px;
  border-radius: 20px;
  height: 100%;
}

.cwc2-event-section-3 p {
  color: #fff;
  font-size: clamp(14px, 2vw, 25px);
  line-height: clamp(20px, 2.5vw, 38px);
}

.cwc2-event-section-5 .event-banner-btn,
.cwc2-event-section-3 .event-banner-btn {
  background-color: #02d85a;
  padding: 15px;
  font-size: clamp(18px, 2vw, 30px);
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  border: 1px solid #275aa6;
  width: fit-content;
  display: flex;
  position: relative;
  margin: auto;
  border-radius: 6px;
}

.cwc2-event-section-4 {
  background: #275aa6;
  background-color: #275aa6;
  opacity: 1;
  background-image: linear-gradient(#3564ac3b 3.6px, transparent 3.6px),
    linear-gradient(to right, #3564ac3b 3.6px, #275aa6 3.6px);
  background-size: 72px 72px;
}

.cwc2-event-section-4 h1 {
  color: #fff;
  font-size: clamp(25px, 4vw, 70px);
}

.cwc2-event-section-4 p {
  color: #275aa6;
  font-size: clamp(12px, 2vw, 25px);
  line-height: clamp(18px, 2.5vw, 35px);
}

.cwc2-event-section-7 h4,
.cwc2-event-section-4 h4 {
  color: #275aa6;
  font-size: clamp(18px, 2vw, 28px);
  line-height: clamp(18px, 2.5vw, 40px);
}

.cwc2-event-section-7 button,
.cwc2-event-section-4 button {
  font-size: clamp(15px, 2vw, 35px);
  padding: 15px;
  border: none;
  border-radius: 5px;
  color: #275aa6;
  font-weight: 600;
  display: flex;
  margin: auto;
}

.cwc-event-form input::placeholder {
  color: lightgray;
}

.cwc-event-footer-section {
  display: block;
  background-image: url("./assets/image/cwc/cwcFooter1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
  background-position: center;
  cursor: pointer;
}

@media (max-width: 1400px) {
  ul.main-nav li a {
    padding-left: 6px;
    padding-right: 6px;
    font-size: 14px;
  }

  .apply-now {
    padding: 10px;
  }

  ul.main-nav li.apply-now a {
    padding: 0;
  }

  .students-pic img {
    max-height: 400px;
  }

  .cwc2-event-banner-section {
    height: 652px;
  }

  .cwc-event-banner-section,
  .cwc-event-footer-section {
    height: 556px;
  }

  .cwc3-event-section-4 iframe,
  .cwc3-event-section-4 video {
    width: 248px;
    border-radius: 32px;
    height: 428px;
  }

  .cwc3-event-section-4 .mobile-wrapper {
    border-radius: 34px;
  }

  .event-banner-section {
    height: 510px;
  }
}

@media (max-width: 1199px) {

  .dropdown,
  ul.main-nav>li>a {
    border-bottom: 1px dashed #8b8b8b;
  }

  .event-banner-section {
    height: 410px;
  }

  .cwc2-event-banner-section,
  .cwc-event-banner-section {
    height: 400px;
    /* background-position: 0%; */
  }

  .dream-para h5 {
    padding: 15px 15px 15px 90px;
  }

  .expert-section .row {
    align-items: inherit;
  }

  .popular-half {
    padding: 20px 0 10px 10px !important;
  }

  .dropdown-header,
  ul.main-nav>li>a {
    padding: 10px;
    color: #fff;
  }

  .service-right .col-sm-12.col-md-6.col-lg-6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .technology-para h4 {
    line-height: 26px;
  }

  .menu-toggle {
    display: inline-block !important;
    border: 0;
    background-color: transparent;
    padding: 0;
    vertical-align: middle;
  }

  button.menu-toggle span {
    height: 2px;
    background-color: #333;
    width: 22px;
    display: block;
    margin: 5px 0;
  }

  .navigation-main {
    transition: 0.3s;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;
    overflow-y: auto;
    height: 100vh;
    background-color: rgb(34 74 152 / 90%);
    left: -100%;
  }

  .navigation-main.menu-active {
    left: 0;
  }

  ul.main-nav {
    padding: 20px;
    text-align: center;
    width: 100%;
  }

  ul.main-nav li {
    width: 100%;
    text-align: center;
    display: block;
  }

  ul.main-nav>li>a {
    width: 100%;
  }

  .close-btn-menu {
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #e31e24;
    position: relative;
    z-index: 9;
  }

  .close-btn-menu::before {
    content: "+";
    font-weight: 300;
    font-size: 30px;
    color: #fff;
    transform: rotate(45deg);
    display: inline-block;
  }

  ul.main-nav li.apply-now a {
    border: 0;
    width: auto;
    padding: 10px 50px;
  }

  ul.main-nav li.apply-now {
    width: auto !important;
    display: inline-block;
    margin: 10px auto 0;
    padding: 0;
  }

  ul.main-nav li.active-a a {
    color: #e31e24;
  }

  header {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .discover-right img {
    max-width: 100%;
  }

  .about-right {
    padding-left: 15px;
    padding-top: 55px;
  }

  .advantage-section .row,
  .under-graduate .row {
    margin-right: 20px;
    margin-left: 20px;
  }

  .annual-section h4 {
    font-size: 18px;
  }

  .guidence-right p br,
  .message-para p br {
    display: none;
  }

  table.summertable {
    display: block;
    overflow-x: auto;
  }

  .process-cycle-item {
    width: 33.333%;
  }

  .cwc-event-section-5 .coffee-bean {
    right: -61%;
    top: -179px;
  }

  .cwc2-event-banner-section {
    height: 562px;
  }

  .cwc-event-banner-section {
    height: 480px;
  }
}

@media (max-width: 991px) {

  .future-section-right span,
  h2 {
    font-size: 30px;
  }

  .students-pic {
    left: 200px;
  }

  .expert-pic img,
  .service-left img {
    max-width: 100%;
  }

  .hear-section p,
  .hear-sectionpara p {
    padding-left: 40px;
    padding-right: 40px;
  }

  .hear button.owl-next::before {
    left: 0;
    top: 45%;
  }

  .hear button.owl-next::after {
    right: 0;
    top: 45%;
  }

  .service-right .col-sm-12.col-md-6.col-lg-6 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .about-right::after,
  .admission-dot-pic img {
    display: none;
  }

  .about-right,
  .future-para {
    padding: 20px;
  }

  .future-section-right h2 {
    font-size: 18px;
  }

  .future-section-left,
  .future-section-left img {
    position: inherit;
  }

  .footer-top-para-right p {
    display: flex;
    align-items: center;
  }

  .course-left {
    padding-right: 20px;
    order: -1;
  }

  .course-align .course-right {
    text-align: left;
  }

  h2 {
    line-height: 58px;
  }

  .about-benner-left h2 {
    line-height: normal;
  }

  .about-benner-left {
    margin-bottom: 30px;
  }

  .infrastructure-left {
    margin-right: 0;
    padding-right: 0;
  }

  .infrastructure-right>img {
    width: 100%;
  }

  .ielts-coaching-right {
    margin-top: 20px;
  }

  .ielts-coaching-right h5 {
    font-size: 15px;
  }

  .ielts-coaching-right form {
    padding: 20px 20px 0;
  }

  .ielts-coaching-right h4 {
    font-size: 19px;
  }

  .studyplan-section .plan {
    margin-top: 0;
  }

  .prepration-right h3,
  .top-features h3 {
    font-size: 24px;
  }

  .ielts-coaching-left {
    padding-top: 30px;
  }

  .ielts-coaching-left li,
  .prepration-right li {
    line-height: 23px;
    padding-bottom: 7px;
  }

  .prepration-left {
    padding-right: 0;
  }

  .about-section {
    padding: 30px 0;
  }

  .about-left li {
    padding-bottom: 8px;
  }

  .about-left {
    padding-top: 0;
    padding-bottom: 25px;
  }

  .university-bottom {
    flex-wrap: wrap;
  }

  .advantage-section .col-sm-6 {
    margin-bottom: 15px;
  }

  .advantage-section,
  .under-graduate {
    padding-top: 15px;
  }

  .work-section {
    padding-left: 0;
  }

  .annual-section h5,
  .annual-section p {
    margin: 0 10px;
  }

  .annual-section h5 {
    font-size: 16px;
    padding-top: 16px;
    padding-bottom: 0;
  }

  .annual-section h4 {
    padding: 10px;
    font-size: 16px;
    line-height: normal;
  }

  .message-para {
    padding: 0 50px;
    margin-bottom: 50px;
  }

  .admission-para {
    padding-right: 20px;
    padding-left: 20px;
  }

  .summer-cycle-twopic img.twopic-last {
    width: 300px;
    height: 300px;
  }

  .summer-range ul li {
    width: 33.333%;
  }

  .process-cycle-item {
    width: 50%;
  }

  .cwc2-event-section-5 img,
  .cwc-event-section-8 img {
    width: 50%;
  }

  .cwc2-event-hero-section .hero-subline {
    margin-bottom: 50px !important;
  }

  .cwc-event-section-7 .container {
    padding-bottom: 50px;
  }

  .cwc-event-section-5 .coffee-bean {
    right: -36%;
    top: -381px;
    width: 83%;
  }

  .cwc2-event-banner-section {
    height: 464px;
  }

  .cwc-event-footer-section {
    height: 400px;
  }

  .cwc3-event-section-3 img {
    height: 80px;
  }

  .event-banner-section {
    height: 330px;
  }
}

@media (max-width: 767px) {
  .event-banner-section {
    height: 250px;
  }

  .cwc2-event-banner-section {
    height: 360px;
  }

  .cwc-event-banner-section {
    height: 300px;
  }

  .cwc-nov-event .event-form-navigation-btn,
  .cwc-event-section-8 .event-banner-btn,
  .cwc-event-goodnews-section .event-banner-btn {
    font-size: 20px;
  }

  .cwc3-event-hero-section .event-btn-wrapper {
    padding: 20px 0;
  }

  .cwc3-event-section-5 .cwc-event-form .submit-btn,
  .cwc3-event-hero-section .event-banner-btn {
    font-size: 20px;
  }

  .education-section,
  .footer-privacy,
  .future-para {
    text-align: center;
  }

  .students span,
  .students-right p,
  body {
    font-size: 14px;
  }

  h1,
  h2 {
    font-size: 24px;
  }

  h2 {
    line-height: 30px;
  }

  .guidence-section h3,
  .our-center-section h4,
  .top-features h3,
  h3 {
    font-size: 20px;
  }

  h4,
  h5 {
    font-size: 16px;
  }

  .dream-head p {
    font-size: 15px;
  }

  .studyplan-section {
    margin-bottom: 90px;
    padding-top: 60px;
  }

  .careerline .carreline-para {
    padding: 0 15px;
  }

  .students-pic {
    left: 110px;
  }

  .prepration-right h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .plan-pic img,
  img {
    max-width: 100%;
  }

  .future-section-right {
    padding-top: 10px;
  }

  .future-section-right h5 {
    width: auto;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 30px;
  }

  .future-section-right h2 {
    font-size: 18px;
    line-height: normal;
    margin-top: 10px;
  }

  .future-section-right img {
    right: 60px;
  }

  .dream-img-nation {
    left: 5px;
  }

  .dream-img-nation img {
    width: 80% !important;
  }

  .hear-sectionpara::after,
  .hear-sectionpara::before {
    left: 0;
  }

  .css-dot::after,
  .css-dot::before {
    right: 0;
  }

  .artical {
    display: block;
    padding: 10px;
  }

  .artical-right {
    padding: 10px 5px;
  }

  .happening-section h4 {
    line-height: normal;
    font-weight: 700;
    text-align: center;
    font-size: 21px;
    margin-bottom: 10px;
  }

  .footer-privacy {
    display: block;
    font-size: 12px;
  }

  .footer-middle li::before {
    top: 8px;
    right: 4px;
  }

  .footer-middle li {
    padding-left: 0;
    padding-right: 15px;
  }

  .artical-right span,
  .policy a {
    font-size: 12px;
  }

  .students {
    padding-bottom: 5px;
  }

  .video-icon {
    font-size: 30px;
    width: 45px;
    height: 45px;
  }

  .future-para,
  .summercamp-def-col {
    padding: 15px;
  }

  .future-section-left img {
    width: 100%;
    max-width: 300px;
    margin: -90px auto auto;
    display: block;
  }

  .careerline,
  .course-section,
  .dream-section,
  .happening-section,
  .hear-section,
  .our-center-section h2,
  .service-section {
    padding: 30px 0;
  }

  .annual-section h4 br,
  .benner-para p br,
  .dream-section .owl-theme .owl-nav,
  .event-section .owl-theme .owl-nav,
  .test-format thead {
    display: none;
  }

  .popular-section {
    padding: 30px 0 0;
  }

  .popular .owl-item .popular-para img {
    width: 25%;
  }

  .popular-para bdi,
  .popular-para i.fa.fa-star {
    padding-left: 0;
  }

  .course-section-left {
    padding-bottom: 0;
  }

  .course-para,
  .under-graduate .col-sm-12 {
    margin-bottom: 10px;
  }

  .course p {
    margin: 0;
  }

  .expert-section {
    padding: 0 0 30px;
  }

  .expert-pic img {
    padding-bottom: 15px;
  }

  .guidence-right,
  .news-section {
    padding-top: 30px;
  }

  .Newsletter {
    padding: 30px 0;
    margin-bottom: 30px;
  }

  .newsletter-para .apply-now {
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer-bottom,
  .footer-bottom-end,
  .footer-bottom-para,
  .footer-middle,
  .footer-middle-para,
  .footer-top,
  .footer-top-para,
  .modal-body {
    padding: 10px 0;
  }

  .students-right {
    line-height: 17px;
  }

  .course-section p {
    font-size: 15px;
    line-height: normal;
  }

  .hear-section p {
    padding-left: 0;
    padding-right: 0;
    font-size: 15px;
    line-height: normal;
  }

  .hear button.owl-next::after,
  .hear button.owl-next::before {
    top: 43px;
    width: 33px;
    background-size: contain;
    background-position: center;
  }

  .about-benner-left h2 {
    line-height: 28px;
  }

  .about-benner-left .col-sm-4 {
    width: 33.333%;
  }

  .about-benner-left h4 {
    margin-top: 10px;
  }

  .about-benner-left p {
    line-height: normal;
  }

  .about-benner {
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .education-section h2 {
    line-height: 29px;
  }

  .mission-section {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .technology-para {
    padding: 10px;
  }

  .ielts-coaching-left,
  .infrastructure-section {
    padding-top: 40px;
  }

  .infrastructure-right-para img {
    max-width: 120px;
    left: 10px;
    border-width: 4px;
    top: auto;
    bottom: -40px;
  }

  .popular-para li {
    line-height: normal;
    margin-bottom: 9px;
  }

  .benner img {
    width: 100%;
    min-height: 250px;
    object-fit: cover;
  }

  .prepration-left {
    padding: 0 0 10px;
  }

  .prepration,
  .under-graduate {
    padding-bottom: 20px;
  }

  .ielts-coaching-right h5 {
    font-size: 14px;
    font-weight: 400;
  }

  .top-features {
    padding: 25px 20px;
  }

  .tp-features {
    padding-top: 0;
  }

  .top-features h5 {
    margin-bottom: 0;
  }

  .top-features .row {
    margin-top: 20px;
  }

  .top-features h5.get-to {
    font-size: 15px;
    font-weight: 500;
  }

  .test-format td {
    display: block;
    width: 100%;
    text-align: left !important;
    padding: 6px 10px;
  }

  .test-format td::before {
    font-weight: 600;
    display: block;
  }

  td.section-td strong {
    font-size: 16px;
    padding-top: 10px;
    font-weight: 700;
    display: block;
  }

  .test-format h3 {
    font-size: 26px;
    text-align: center;
  }

  .training-section h3 {
    font-size: 23px;
    line-height: 30px;
  }

  .training-top {
    text-align: left;
  }

  .reading-pic {
    width: 100px;
    min-width: 100px;
  }

  .carreline-para li {
    padding-bottom: 10px;
    line-height: 21px;
  }

  .university-para {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 10px 0 0;
    gap: 10px;
  }

  .university-head {
    font-weight: 600;
    line-height: normal;
  }

  .university-section {
    padding-top: 35px;
    padding-bottom: 50px;
  }

  .advantage-section .row,
  .under-graduate .row {
    margin-right: 0;
    margin-left: 0;
  }

  .work-section {
    padding-top: 20px;
  }

  .work-section li {
    line-height: normal;
    padding-bottom: 11px;
  }

  .annual-cost-section {
    padding: 35px 0;
  }

  .guidence-left p {
    line-height: 22px;
  }

  .guidence-right p {
    margin-bottom: 20px;
  }

  .guidence-section {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .admission-para {
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .blog-btn button.apply-now {
    margin-top: 0;
  }

  .message-para {
    padding: 0 20px;
  }

  .input-phone {
    flex-direction: column;
    padding-bottom: 15px;
  }

  .input-phone input,
  .input-phone select {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .message-section {
    padding-top: 25px;
    padding-bottom: 35px;
    margin-bottom: 0;
    margin-top: 30px;
  }

  .admission-para h2 {
    padding-bottom: 10px;
  }

  .container.mes-form-container {
    padding: 0;
  }

  table.summertable {
    display: table;
  }

  .summercamp-first img {
    height: 300px;
    width: 300px;
  }

  .summer-range ul li {
    width: 50%;
  }

  .process-cycle-item {
    width: 100%;
  }

  .modal-content {
    padding: 14px;
  }

  .foundation-input-para {
    flex-direction: column;
    gap: 0;
  }

  .modal.show .modal-dialog {
    margin: 20px auto;
  }

  .accordion-body {
    padding: 20px;
  }

  button.accordion-button {
    line-height: normal;
    font-size: 15px;
  }

  .custom-banner-home {
    padding: 180px 0 100px;
  }

  .bannercustomdata {
    width: 250px;
    height: 250px;
    font-size: 12px;
    line-height: normal;
  }

  .box-images,
  .box-images.bank {
    width: 60px;
    height: 60px;
  }

  .student-countrylist {
    right: 0;
    padding: 8px;
  }

  .box-images img {
    max-height: 25px;
  }

  .box-images.leaf {
    left: 11px;
    top: 40px;
  }

  .box-images.bank {
    right: 20px;
    top: 20px;
  }

  .summer-cycle-left h3 {
    margin-top: 60px;
  }

  .cwc-event-section-5 .coffee-bean {
    top: -284px;
  }

  .cwc-event-footer-section {
    height: 300px;
  }

  .cwc-event-section-9 .right-advice,
  .cwc-event-section-7 .right-advice {
    width: 60%;
  }

  .cwc3-event-section-4 iframe,
  .cwc3-event-section-4 video {
    width: 179px;
    border-radius: 21px;
    height: 305px;
  }

  .cwc3-event-section-4 .mobile-wrapper {
    border-radius: 26px;
  }

  .cwc3-event-section-7 button.accordion-button {
    padding: 5px 20px !important;
  }
}

@media (max-width: 600px) {

  .border-table td,
  .border-table th {
    font-size: 14px;
  }

  .event-banner-section {
    height: 200px;
  }
}

@media (max-width: 499px) {
  .students-pic {
    left: 50px;
  }

  .event-banner-section {
    height: 150px;
  }

  .cwc2-event-banner-section,
  .cwc-event-banner-section {
    /* background-position: 0%; */
    height: 200px;
  }

  .cwc-nov-event .event-form-navigation-btn,
  .cwc-event-section-8 .event-banner-btn,
  .cwc-event-goodnews-section .event-banner-btn {
    font-size: 12px;
  }

  .cwc3-event-section-5 .cwc-event-form .submit-btn,
  .cwc3-event-hero-section .event-banner-btn {
    font-size: 12px;
  }

  .event-banner-section .event-banner-bottom {
    width: 100%;
    align-items: end;
  }

  .event-testimonials .testimonials-list {
    margin-top: 20px;
  }

  .event-testimonials .testimonial-prev-arrow {
    top: -50px;
    right: 60px;
  }

  .event-testimonials .testimonial-next-arrow {
    top: -50px;
    right: 0;
  }

  .event-footer .footer-imgs img {
    height: 50px;
  }

  .cwc-event-section-5 .coffee-bean {
    top: -252px;
  }

  .cwc2-event-banner-section,
  .cwc-event-banner-section,
  .cwc-event-footer-section {
    height: 220px;
  }
}

.ielts-pricing-block {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  background-color: #f0f8ff;
  margin: auto 0;
}

.ielts-pricing-info {
  padding-left: 10px;
  padding-right: 10px;
}

.ielts-pricing-info h3 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #224a98;
}

.ielts-price-table table {
  border: 0;
}

.ielts-price-table table thead {
  background: #d5e3ff;
}

fieldset,
hr,
input,
pre,
table,
table *,
textarea {
  border-color: #dcd7ca;
}

td,
th {
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.ielts-contact-info {
  margin: 30px 0;
}

.ielts-contact-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.ielts-contact-list li {
  width: calc(100% / 3);
  background: #d5e3ff;
  padding: 15px;
  text-align: center;
  margin: 0;
  border: 2px solid #fff;
  list-style: none;
}

.ielts-contact-list li h5 {
  margin: 0 0 5px;
  font-size: 18px;
  color: #224a98;
}

.ielts-paryment-qr {
  background: #1967d3;
}

.wpb_button,
.wpb_content_element,
ul.wpb_thumbnails-fluid>li {
  margin-bottom: 35px;
}

.entry-content li,
.entry-content p,
.featured-desc,
.post .entry-content {
  margin: 0 0 26px;
  color: #505050;
  line-height: 1.6;
  font-size: 14px;
  font-family: Rubik, sans-serif;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
  font-size: 13px;
  font-style: italic;
}

.dataofHelping {
  margin: 30px auto;
}

.ielts-contact-info a {
  text-decoration: none;
  outline: 0;
  color: #000;
}

.ielts-payment-qr {
  margin: 0 auto 40px;
  height: fit-content;
  width: fit-content;
  padding: 0 170px;
  background-color: #1967d3;
}

.education-event-reg-form {
  padding: 20px;
  background: linear-gradient(45deg, #8daeed, #cfe5ed);
}

.education-form-label {
  font-weight: 900;
}

.crms-header-title {
  margin: 0;
  padding: 10px;
  border-radius: 0.375rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
  background: #2e4993;
  color: #fff;
}

.crms-title {
  margin: 0;
  padding: 10px;
  border-radius: 0.375rem;
  background: #f0f3fb;
}

.tabel-border-none td {
  border: none;
  padding-left: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.event-photos-carosoul {
  text-align: center;
  padding: 30px 0 50px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}

.youtube-btn {
  border: none;
  background: red;
  border-radius: 4px;
  padding: 5px;
}

.page-title {
  color: #1f1f1f;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: 1s linear infinite spin;
}

.thankyou-wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  background: #fff;
  padding: 10px 0 50px;
}

.thankyou-wrapper h1 {
  text-align: center;
  color: #333;
  padding: 0 10px 10px;
  margin-top: 0;
  margin-bottom: 0;
  height: 250px;
}

.thankyou-wrapper h1 img {
  margin: 0 auto;
  height: 300px;
}

.thankyou-wrapper p {
  text-align: center;
  color: #333;
  padding: 5px 10px 10px;
  font-size: 25px;
  margin-bottom: 0;
}

.thankyou-wrapper a {
  text-align: center;
  color: #fff;
  display: block;
  text-decoration: none;
  border-radius: 10px;
  width: 250px;
  background: #e21b20;
  margin: 10px auto 0;
  padding: 15px 20px;
  border-bottom: 5px solid #e21b20;
}

.button,
.page-not-found-leftside p {
  color: #000;
}

.thankyou-wrapper a:hover {
  background: #224a98;
  border-bottom: 5px solid #224a98;
}

.svg-inline--fa {
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  display: inline-block;
  box-sizing: content-box;
}

.logofooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.venue-width {
  width: 120px;
}

.roulette-container {
  position: relative;
  width: 100%;
  max-width: 326px;
  aspect-ratio: 1;
  margin: 263px auto 0 67px;
}

.roulette-container>div {
  width: 100%;
  height: 100%;
  max-width: unset;
  max-height: unset;
}

.button {
  font-size: larger;
  border: none;
  outline: 0;
  background: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  padding: 5px;
  cursor: pointer;
}

.roulette-button {
  position: absolute;
  top: 50%;
  left: 51%;
  z-index: +9;
  border-radius: 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
  border: 8px solid #e73a67;
  transform: translate(-55%, -55%);
  background: #23448b;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
}

.event-ad-modal-btn,
.spin-button {
  color: #fff;
  border: none;
  font-weight: 600;
}

.spin-button {
  margin-left: 130px;
  width: 200px;
  padding: 8px 8px 28px;
  margin-top: 46px;
  border-radius: 10px;
  background-color: #204b99;
  font-size: 24px;
}

.box-modal,
.spinner-modal {
  padding: 30px 40px;
  background: #fff;
  overflow: hidden;
}

.gTLZXx {
  position: absolute !important;
  z-index: 5 !important;
  width: 10% !important;
  right: 30px !important;
  top: 30px !important;
}

.box-modal,
.box-modal img,
.spinner-modal img {
  width: fit-content;
}

.box-modal,
.event-ad-modal,
.spinner-modal {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-modal {
  border-radius: 10px;
  width: 50%;
}

.event-ad-modal {
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: url("./assets/image/IELTS\ EXAM/ieltsmocktest4.jpg") 0 0 / cover no-repeat;
  /* For Normal Images */
  /* height: 540px;
  width: 720px; */

  /* For IELTS Landing Page */
  height: 606px;
  width: 450px;
}

.event-ad-modal-btn {
  background-color: #224a96;
  padding: 15px;
  border-radius: 5px;
  margin: 10px;
  font-size: 20px;
  width: 200px;
  position: absolute;
  top: 4px;
  right: 160px;
  box-shadow: 2px 2px 5px #000;
}

.title-Win-1,
.title-Win-2 {
  text-align: center;
  font-size: 32px;
}

.event-popup-close {
  cursor: pointer;
  position: fixed;
  top: -55px;
  right: -4px;
  filter: invert(1);
}

.event-ad-modal-btn:hover {
  background-color: #f84545;
}

.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9910;
}

.error-message,
.title-Win-1 {
  color: red;
}

.closeContainer {
  display: flex;
  justify-content: center;
}

.closebtn {
  margin-top: 30px;
  width: 240px;
  height: 54px;
  cursor: pointer;
  font-size: 17px;
  color: #fff;
  border: none;
  outline: 0;
  background: #2466ff;
  border-radius: 5px;
}

.page-not-found-box p {
  font-size: 18px;
  font-weight: 400;
}

.page-not-found-box {
  width: 50%;
}

.page-not-found {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: calc(100vh - 158px);
}

.page-not-found-text-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 158px);
}

.page-not-found-leftside h3,
.page-not-found-leftside h5 {
  color: #000;
  font-weight: 400;
}

.page-not-found-leftside h5 {
  line-height: normal;
  padding: 1px 0 10px;
  margin-top: 20px;
}

#wheel {
  display: flex !important;
}

#wheel canvas {
  width: 700px !important;
  height: 600px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1350px) {
  .Iframe-className {
    height: 250px;
    width: 300px;
  }

  .venue-width {
    width: 150px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .venue-width {
    width: 165px;
  }
}

@media only screen and (max-width: 920px) and (min-width: 768px) {
  .Iframe-className {
    height: 250px;
    width: 370px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 490px) {
  .Iframe-className {
    height: 250px;
    width: 440px;
  }

  .venue-width {
    width: 70px;
  }
}

@media (max-width: 490px) {

  .dataofHelping,
  .ielts-price-table {
    margin: 0 auto;
  }

  .dream-para h5 {
    padding: 15px 80px 15px 90px;
  }

  .logofooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .venue-width {
    width: 110px;
  }

  .roulette-button {
    border-radius: 3rem;
    width: 3rem;
    height: 3rem;
  }

  .gTLZXx {
    position: absolute !important;
    z-index: 5 !important;
    width: 8% !important;
    right: 30px !important;
    top: 30px !important;
  }
}

@media (max-width: 768px) {
  .ielts-pricing-block {
    flex-direction: column;
  }

  .ielts-pricing-block table {
    width: 100%;
  }

  .ielts-payment-qr {
    padding: 0;
  }

  .dream-para h5 {
    padding: 15px 80px 15px 90px;
  }

  .canadian-section .slick-slide img {
    display: initial;
  }

  .sample-prev-arrow1 {
    margin-top: -200px;
    margin-left: 15px;
  }

  .next-arrow1 {
    margin-top: -200px;
  }

  .input-phone input {
    margin-bottom: 0 !important;
  }

  .event-ad-modal {
    background: url("./assets/image/IELTS\ EXAM/ieltsmocktest4.jpg") 0 0 / cover no-repeat;
    justify-content: center;
    /* For Normal Images */
    /* width: 385px; */

    /* For IELTS Landing Page */
    height: 472px;
    width: 350px;
  }

  .event-ad-modal-btn {
    height: 34px;
    width: 131px;
    margin: 0;
    padding: 0;
    font-size: 15px;
    position: absolute;
    top: 84px;
    left: 23px;
    background: #ff0;
    color: #000;
  }

  .event-ad-modal-btn:hover {
    color: #fff;
  }

  .page-not-found-text-boxes {
    flex-direction: column;
    text-align: center;
  }

  .page-not-found-leftside p {
    margin-top: 0;
  }

  .page-not-found-leftside h5 {
    margin: 0 0 7px;
  }

  .swiper {
    padding-top: 30px !important;
    padding-bottom: 40px !important;
  }

  .cwc3-event-section-4 .swiper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-container input {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.checkbox-container label {
  flex-grow: 1;
}

.error-border {
  border: 1px solid red !important;
}

.error-label {
  color: red !important;
}

.table-container {
  overflow-x: auto;
}

.border-table {
  border-collapse: collapse;
  width: 100%;
}

.border-table td,
.border-table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

@media (max-width: 524px) {
  .cwc-event-clgfair-section .college-fair-sec1 {
    align-items: center;
  }

  .cwc3-event-hero-section .counts-image {
    height: 150px;
    margin-bottom: 10px;
    width: auto;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }
}

@media (min-width: 2160px) {

  .cwc2-event-banner-section,
  .cwc-event-banner-section,
  .cwc-event-footer-section {
    height: 1290px;
  }

  .event-banner-section {
    height: 890px;
  }
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  /* height: 300px; */
}

.swiper-slide img {
  display: block;
  width: 100%;
}