.ielts-section-1 {
  color: #fff;
  /* height: 500px; */
  background: url("../../assets/image/dummy/grebackgaround.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ielts-section-1 h1 {
  color: #fff;
  font-size: clamp(20px, 2vw, 40px);
  font-weight: bold;
}

.ielts-section-1 .ielts-form .card-body {
  padding: 32px;
}

.ielts-section-2 p,
.ielts-section-1 p {
  font-size: clamp(13px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.ielts-section-1 .submit-btn {
  border: none;
  padding: 10px 15px;
  background: dodgerblue;
  color: white;
  border-radius: 5px;
  font-size: 15px;
  line-height: 23px;
}

.ielts-section-5 h1,
.ielts-section-2 h1 {
  text-align: center;
  font-size: clamp(25px, 2.5vw, 60px);
  font-weight: bold;
}

.ielts-section-2 .ielts-cards {
  background: linear-gradient(180deg, #fff, #cee6ff);
  box-shadow: 0 0 20px lightgray;
  padding: 15px;
  text-align: center;
}

.ielts-section-2 .ielts-cards p {
  font-weight: 600;
}

.ielts-section-2 img {
  height: 100px;
}

.ielts-section-5 {
  background: rgb(206, 230, 255);
}

.ielts-section-5 .ielts-faqs {
  margin-bottom: 10px;
}

.ielts-section-5 .ielts-faqs .ielts-faqs-header {
  font-size: clamp(18px, 1.5vw, 25px);
  line-height: clamp(25px, 2.5vw, 50px);
  font-weight: 500;
  /* line-height: 45px; */
}

.ielts-section-5 .ielts-faqs .ielts-faqs-content {
  font-size: clamp(15px, 1.5vw, 20px);
  line-height: clamp(20px, 2.5vw, 30px);
}

.PhoneInput.PhoneInput--focus input {
  outline: none !important;
}

.PhoneInput input {
  border: none !important;
}

/* .ielts-section-3 h1 {
    text-align: center;
    font-size: clamp(25px, 2.5vw, 60px);
    font-weight: bold;
}

.ielts-section-3 .feature-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ielts-section-3 .feature-item {
    background-color: #ffffff;
    padding: 20px;
    border-left: 5px solid mediumseagreen;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.ielts-section-3 .feature-item h2 {
    color: mediumseagreen;
    font-size: clamp(20px, 2.5vw, 30px);
    line-height: clamp(20px, 4.5vw, 30px);
    margin-bottom: 15px;
}

.ielts-section-3 .feature-item p {
    color: #555;
    font-size: clamp(14px, 1.5vw, 20px);
    line-height: clamp(20px, 2.5vw, 30px);
} */

.ielts-section-3 .feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ielts-section-3 .feature-item {
  background-color: #ffffff;
  padding: 20px;
  border-left: 5px solid mediumseagreen;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 15px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 0 0 calc(51% - 45px); /* 3 items per row on desktop */
}

.ielts-section-3 .feature-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.ielts-section-3 .feature-item h2 {
  color: mediumseagreen;
  font-size: clamp(20px, 2.5vw, 30px);
  margin-top: 20px;
  line-height: clamp(22px, 3vw, 41px);
  margin-bottom: 10px;
}

.ielts-section-3 .feature-item p {
  color: #555;
  font-size: clamp(14px, 1.5vw, 18px);
  line-height: 1.6;
}

.ielts-section-3 .feature-icon {
  margin-bottom: 20px;
  perspective: 1000px; /* Add perspective to create a 3D effect */
}

.ielts-section-3 .feature-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  border: 2px solid mediumseagreen;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth effect */
}

.ielts-section-3 .feature-item:hover .feature-image {
  transform: rotateY(10deg) rotateX(10deg) scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Optional: increase shadow for more depth */
}

.ielts-section-4 {
  background: #fff;
}

.ielts-section-4 .testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

/* Testimonial Card */
.testimonial-card {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.avatar {
  width: 70px;
  border-radius: 50%;
  border: 3px solid #ff6347;
}

.testimonial-name {
  font-size: 1.5rem;
  color: #333;
  font-weight: 700;
}

.testimonial-role {
  font-size: 0.9rem;
  color: #666;
}

.testimonial-text {
  font-size: 1.1rem;
  color: #555;
  font-style: italic;
  margin: 0 10px;
  padding-top: 10px;
  position: relative;
}

.testimonial-text::before {
  content: "“";
  font-size: 3rem;
  color: #ff6347;
  position: absolute;
  top: -20px;
  left: 10px;
}

.testimonial-text::after {
  content: "”";
  font-size: 3rem;
  color: #ff6347;
  position: absolute;
  bottom: -10px;
  right: 10px;
}

/* Call to Action Section */
.cta-section {
  background-color: cornflowerblue;
  color: white;
  padding: 80px 20px;
  text-align: center;
  margin-top: 50px;
  clip-path: polygon(0 0, 100% 15%, 100% 100%, 0% 100%);
}

.cta-title {
  color: #fff;
  font-size: clamp(25px, 2.5vw, 60px);
  font-weight: 700;
  margin-bottom: 20px;
}

/* .cta-description {
    font-size: 1.2rem;
    margin-bottom: 40px;
} */

.cta-button {
  cursor: pointer;
  display: inline-block;
  padding: 15px 40px;
  background-color: white;
  color: cornflowerblue;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.cta-button:hover {
  color: cornflowerblue;
  box-shadow: 0 0 0 5px #ffffff57, 0 0 0 10px #ffffff3b, 0 0 0 15px #ffffff17;
  transition: 0.2s linear;
}

.play-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 50px;
  background-color: transparent;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  color: #fff;
}

.play-button .play-icon {
  margin-right: 10px;
  font-size: 20px;
  transition: all 0.3s ease;
  color: #fff;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Optional: Add specific icon hover effects */
/* .play-button .play-icon {
  color: green;
} */

/* .play-button:hover .play-icon {
  color: white;
} */

/* Responsive Design */
@media (min-width: 768px) {
  /* .ielts-section-3 .feature-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .ielts-section-3 .feature-item {
    transition: transform 0.3s ease;
  }

  .ielts-section-3 .feature-item:hover {
    transform: translateY(-5px);
  } */

  .testimonial-grid {
    grid-template-columns: 1fr;
  }

  .cta-title {
    font-size: 2rem;
  }

  /* .cta-description {
        font-size: 1rem;
    } */
}

@media (max-width: 1140px) {
  .ielts-section-2 img {
    height: 80px;
  }

  /* .ielts-section-3 .feature-item {
    flex: 0 1 calc(51% - 45px); 
  } */
}

@media (max-width: 768px) {
  /* Small devices and above */
  .ielts-section-3 .feature-item {
    flex: 0 1 calc(100% - 45px); /* 2 items per row on tablets and small devices */
  }
}
